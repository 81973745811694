import { Box } from '@mui/material'
import React from 'react'
import AddTemplet from './addTemplet/AddTemplet'
import TempletList from './templetList/TempletList'
import { GlobalContext } from '../../context/GlobalContext'
import SendBroadcast from './sendBroadcast/SendBroadcast'

const SideScreen = ({ setMenu, menu, state, setState, data, screenWidth }) => {
    const [templetList, setTempletList] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)

    async function getTemplets() {
        const res = await hitAxios({
            path: "/api/user/get_my_meta_templets",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setTempletList(res.data.data)
        }
    }

    React.useEffect(() => {
        getTemplets()
    }, [])

    return (
        <Box p={2} >
            {menu.selected === "CREATE_TEMPLET" &&
                <>
                    <AddTemplet
                        setMenu={setMenu}
                        menu={menu}
                        state={state}
                        setState={setState}
                        getTemplets={getTemplets}
                        data={data}
                        screenWidth={screenWidth}
                    />

                    <TempletList
                        templetList={templetList}
                        setTempletList={setTempletList}
                        getTemplets={getTemplets}
                        data={data}
                        screenWidth={screenWidth}
                    />
                </>}

            {menu.selected === "SEND_BROADCAST" &&
                <SendBroadcast
                    setMenu={setMenu}
                    menu={menu}
                    state={state}
                    setState={setState}
                    data={data}
                    screenWidth={screenWidth}
                />}
        </Box>
    )
}

export default SideScreen