import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit, Login } from '@mui/icons-material'
import EditUser from './EditUser';
import { GlobalContext } from '../../context/GlobalContext';

const UsersList = ({ data, users, setState, state, getUsers }) => {
    const { hitAxios } = React.useContext(GlobalContext)

    async function direcLogin(e) {
        const res = await hitAxios({
            path: "/api/admin/auto_login",
            post: true,
            admin: true,
            obj: { uid: e }
        })

        if (res.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_user", res.data.token)
            window.open('/user')
        }
    }


    return (
        <div>
            <Box mt={4} borderRadius={4} bgcolor={'action.hover'} >
                {users ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...users].reverse()}
                            columns={[
                                {
                                    headerName: data.autoLogin, field: 'auto_login', flex: 1, renderCell: row => {
                                        return (
                                            <Tooltip title={data.autoLogin} >
                                                <IconButton
                                                    onClick={() => direcLogin(row.row.uid)}
                                                >
                                                    <Login />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.name, field: 'name', flex: 1, renderCell: row => {
                                        return (
                                            <Tooltip title={row.row.name} >
                                                {row.row.name}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.mobNo, field: 'mobile_with_country_code', flex: 1, renderCell: row => {
                                        return (
                                            <Tooltip title={row.row.mobile_with_country_code} >
                                                {row.row.mobile_with_country_code}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.timeZone, field: 'timezone', flex: 1, renderCell: row => {
                                        return (
                                            <Tooltip title={row.row.timezone} >
                                                {row.row.timezone}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.plan, field: 'plan', flex: 1, renderCell: row => {
                                        return (
                                            row?.row?.plan ? JSON.parse(row?.row?.plan)?.title : data.na
                                        )
                                    }
                                },
                                {
                                    headerName: data.edit, field: 'edit', flex: 1, renderCell: row => {
                                        return (
                                            <IconButton onClick={() => {
                                                setState({ ...state, dialogEdit: true, dialogEditData: row.row })
                                            }} >
                                                <Edit />
                                            </IconButton>
                                        )
                                    }
                                },
                                {
                                    headerName: data.delete, field: 'del', flex: 1, renderCell: row => {
                                        return (
                                            <IconButton color='error' >
                                                <DeleteOutline />
                                            </IconButton>
                                        )
                                    }
                                }
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>

            <EditUser
                getUsers={getUsers}
                state={state}
                setState={setState}
                data={data}
            />

        </div>
    )
}

export default UsersList