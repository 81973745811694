import React from 'react';
import { Box, Button, CardActionArea, Stack, Typography } from '@mui/material';
import Slider from "react-slick";
import { GlobalContext } from '../context/GlobalContext';
import { TranslateContext } from '../context/TranslateContext'
import ButtonComp from '../context/ButtonComp';
import { AddShoppingCart, Cancel, CancelOutlined, CheckCircle, CheckCircleOutline, DeleteOutline } from '@mui/icons-material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const PlanComponent = ({ admin, bgColor }) => {
    const { data } = React.useContext(TranslateContext)
    const [plans, setPlans] = React.useState([])
    const [web, setWeb] = React.useState({})
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)
    const history = useHistory()

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: plans ? plans?.length > 3 ? 3 : plans?.length : 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 6000,
        prevArrow: null, // Remove the previous arrow
        nextArrow: null, // Remove the next arrow
        responsive: [
            {
                breakpoint: 800, // Set the breakpoint for mobile devices
                settings: {
                    slidesToShow: 1, // Set the number of slides to show at a time to 1
                    slidesToScroll: 1, // Set the number of slides to scroll at a time to 1
                }
            }
        ]
    };

    async function delPlan(id) {
        if (window.confirm(data.AUS)) {
            const res = await hitAxios({
                path: "/api/admin/del_plan",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                window.location.reload()
            }
        }
    }

    async function getWebPublic() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    async function getPlans() {
        const res = await hitAxios({
            path: "/api/admin/get_plans",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setPlans(res.data.data)
        }
    }

    React.useEffect(() => {
        getPlans()
        getWebPublic()
    }, [])


    return (
        plans &&
        <Box>

            <Slider {...settings}>
                {plans.map((i, key) => {
                    return (
                        <div key={key}>
                            <Box >
                                <Box mr={2}
                                    minHeight={400}
                                    sx={{ borderLeft: 3, borderColor: '#287F27', borderTop: 3, borderTopColor: '#287F27' }}
                                    borderRadius={3} bgcolor={bgColor ? bgColor : 'action.hover'} p={3}>
                                    <Stack direction={'column'} spacing={2}>
                                        <Box position={'relative'}>
                                            <Box sx={{ bgcolor: (t) => "white" }} p={2} borderRadius={2}>
                                                <Stack spacing={1} alignItems={'center'} direction={'column'}>
                                                    <Typography sx={{ color: (t) => t.palette.primary.main }} variant='h6' >{i.title}</Typography>
                                                    <Typography align='center' color={'black'} variant='body2' >{i.short_description}</Typography>
                                                </Stack>
                                            </Box>

                                            <Box top={4} bottom={0} right={4} left={0} borderRadius={2} border={2} borderColor={'#E3E3E3'} position={'absolute'} />
                                        </Box>

                                        <Stack alignItems={'center'} direction={'column'} spacing={2}>
                                            <Stack direction={'row'} spacing={1}>
                                                <span style={{ textDecoration: 'line-through', color: 'gray', fontSize: 12 }}>{web?.currency_symbol}{i.price_strike}</span>
                                                <Typography variant='h6' >{web?.currency_symbol}{i.price}</Typography>
                                            </Stack>
                                            <Typography variant='body2' >{data.for} {i.plan_duration_in_days} {data.days}</Typography>
                                        </Stack>
                                    </Stack>

                                    {!admin && <Box mt={3}>
                                        <Stack alignItems={'center'} >
                                            <ButtonComp
                                                onClick={() => {
                                                    history.push(`/user/checkout?product=${i?.id}`)
                                                }}
                                                idSuffix="2"
                                                startIcon={<AddShoppingCart />}
                                                title={data.selectPlan} />
                                        </Stack>
                                    </Box>}

                                    <Box mt={3}>
                                        <Stack alignItems={'center'} direction={'column'} spacing={1} >
                                            <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                <Typography variant='body2' >{data.chatTags}</Typography>
                                                {i?.allow_tag < 1 ? <CancelOutlined sx={{ color: 'red', height: 20, width: 20 }} /> :
                                                    <CheckCircleOutline sx={{ color: 'green', height: 20, width: 20 }} />}
                                            </Stack>

                                            <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                <Typography variant='body2' >{data.chatNote}</Typography>
                                                {i?.allow_note < 1 ? <CancelOutlined sx={{ color: 'red', height: 20, width: 20 }} /> :
                                                    <CheckCircleOutline sx={{ color: 'green', height: 20, width: 20 }} />}
                                            </Stack>

                                            <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                <Typography variant='body2' >{data.chatBot}</Typography>
                                                {i?.allow_chatbot < 1 ? <CancelOutlined sx={{ color: 'red', height: 20, width: 20 }} /> :
                                                    <CheckCircleOutline sx={{ color: 'green', height: 20, width: 20 }} />}
                                            </Stack>

                                            <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                <Typography variant='body2' >{data.cloudAPI}</Typography>
                                                {i?.allow_api < 1 ? <CancelOutlined sx={{ color: 'red', height: 20, width: 20 }} /> :
                                                    <CheckCircleOutline sx={{ color: 'green', height: 20, width: 20 }} />}
                                            </Stack>
                                        </Stack>

                                        {admin &&
                                            <Stack alignItems={'center'} mt={4}>
                                                <ButtonComp
                                                    onClick={() => delPlan(i?.id)}
                                                    bgcolor="#F55D5D"
                                                    startIcon={<DeleteOutline />}
                                                    title={data.delPlan} />
                                            </Stack>}
                                    </Box>
                                </Box>

                            </Box>
                        </div>
                    )
                })}
            </Slider>
        </Box>
    );
};

export default PlanComponent;
