import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { AddCircleOutline } from '@mui/icons-material'
import AddPlan from './AddPlan'
import ButtonComp from '../../context/ButtonComp'
import { GlobalContext } from '../../context/GlobalContext'
import PlanList from './PlanList'

const ManagePlans = () => {
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [planList, setPlanList] = React.useState([])
    const [state, setState] = React.useState({
        dialog: false
    })

    async function getPlan() {
        const res = await hitAxios({
            path: "/api/admin/get_plans",
            post: false,
            admin: true,
            token_user: ""
        })
        if (res.data.success) {
            setPlanList(res.data.data)
        }
    }

    React.useEffect(() => {
        getPlan()
    }, [])

    return (
        <Box mt={2} >
            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'}>
                <Stack direction={'column'}>
                    <Typography
                        fontWeight={'bold'}
                        variant='h6'>{data.managePLan}</Typography>
                    <Typography
                        color={'gray'}
                        variant='caption'>{data.managePlanDes}</Typography>
                </Stack>

                <ButtonComp
                    idSuffix="3"
                    onClick={() => setState({ ...state, dialog: true })}
                    startIcon={<AddCircleOutline sx={{ height: 20, width: 20 }} />}
                    title="Add New" />
            </Stack>

            <AddPlan getPlan={getPlan} state={state} setState={setState} data={data} />
            <PlanList planList={planList} setPlanList={setPlanList} getPlan={getPlan} state={state} setState={setState} data={data} />
        </Box>
    )
}

export default ManagePlans