import { Avatar, Box, Button, CardActionArea, CardMedia, Checkbox, Container, Divider, Grid, IconButton, Radio, Stack, Switch, Tooltip, Typography, Zoom } from '@mui/material'
import React from 'react'
import ButtonComp from '../../context/ButtonComp'
import ReactPlayer from 'react-player'
import { ArrowBack, ArrowCircleRightOutlined, CheckCircleOutline, PermContactCalendarOutlined, PlayArrowOutlined, PlayCircleOutline, SmartToy, SmartToyOutlined } from '@mui/icons-material'
import moment from 'moment'
import TextFieldNew from '../../components/TextFieldNew'

const CreateChatbot = ({ data, screenWidth, chats, chatFlow, getChatbots, state, setState, hitAxios, setSelChat, selChat, selFlow, setSelFlow }) => {

    async function addChatbot() {
        const res = await hitAxios({
            path: "/api/chatbot/add_chatbot",
            post: true,
            admin: false,
            obj: {
                title: state.title,
                chats: selChat,
                flow: selFlow,
                for_all: state.for_all
            }
        })
        if (res.data.success) {
            setSelChat([])
            setSelFlow("")
            setState({ ...state, add: false })
            getChatbots()
        }
    }

    async function updateChatbot() {
        const res = await hitAxios({
            path: "/api/chatbot/update_chatbot",
            post: true,
            admin: false,
            obj: {
                title: state.title,
                chats: selChat,
                flow: selFlow,
                for_all: state.for_all,
                id: state?.botId
            }
        })
        if (res.data.success) {
            setSelChat([])
            setSelFlow("")
            setState({ ...state, add: false })
            getChatbots()
        }
    }

    return (
        <div>
            <Box m={3}>
                {!state.add &&
                    <Box p={2} bgcolor={'white'} borderRadius={2} >
                        <Grid container spacing={4} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                            <Grid xs={12} sm={12} md={6} lg={6} item>
                                <Stack direction={'column'} spacing={3}>
                                    <CardMedia
                                        sx={{ height: "100%", width: 200 }}
                                        src='/assets/chatbot_title.svg' component={'img'} />
                                    <Typography color={'gray'} variant='body2' >
                                        {data.chatBotDes}
                                    </Typography>

                                    <Button
                                        onClick={() => setState({ ...state, add: true, editing: false })}
                                        fullWidth
                                        startIcon={<SmartToyOutlined />}
                                        sx={{ boxShadow: 0, borderRadius: 2, maxWidth: screenWidth > 893 ? 300 : "100%" }}
                                        variant='contained'
                                    >{data.addNew}</Button>
                                </Stack>
                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} item>
                                <Stack alignItems={'center'}>
                                    <Tooltip title={data.playTutorial} >
                                        <Box sx={{ cursor: 'pointer' }} p={4} bgcolor={'#F3F3F3'} borderRadius={'50%'} >
                                            <PlayCircleOutline sx={{ height: 50, width: 50, color: 'gray' }} />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Box>}

                {state.add &&
                    <Zoom in={state.add} >
                        <Box p={2} bgcolor={'white'} borderRadius={2} >
                            <Box mb={3} >
                                <Stack direction={'row'} spacing={2}>
                                    <IconButton
                                        onClick={() => setState({ ...state, add: false })}
                                        sx={{ color: (t) => t.palette.primary.main }} >
                                        <ArrowBack />
                                    </IconButton>

                                    <TextFieldNew
                                        label={data.chatBotTitle}
                                        value={state.title}
                                        onChange={(e) => setState({ ...state, title: e.target.value })}
                                    />
                                </Stack>
                            </Box>

                            <Grid container spacing={4} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                <Grid xs={12} sm={12} md={6} lg={6} item>
                                    <Box mb={4} >
                                        <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                            <Tooltip title={data.selAll} >
                                                <Checkbox
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelChat(chats)
                                                        } else {
                                                            setSelChat([])
                                                        }
                                                    }}
                                                    checked={chats.length === selChat.length ? true : false} />
                                            </Tooltip>

                                            <Divider variant='middle' orientation="vertical" flexItem />
                                            <Switch
                                                onChange={(e) => setState({ ...state, for_all: e.target.checked })}
                                                checked={state.for_all}
                                            />

                                            <Typography variant='body2' fontWeight={500} color={'gray'} >{data.turnOnForNewChat}</Typography>
                                        </Stack>
                                    </Box>

                                    {chats.length > 1 ?
                                        <Box overflow={'auto'} height={350} >
                                            <Stack direction={'column'} spacing={2}>
                                                {chats?.map((i, key) => {
                                                    return (
                                                        <Stack key={key} i={i} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                            <Stack direction={'row'} spacing={1}>
                                                                <Checkbox
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setSelChat([...selChat, i])
                                                                        } else {
                                                                            const newOne = selChat?.filter((ii) => ii.id !== i?.id)
                                                                            setSelChat(newOne)
                                                                        }
                                                                    }}
                                                                    checked={selChat?.map(ii => ii.id)?.includes(i?.id) ? true : false}
                                                                />

                                                                <Stack direction={'column'}>
                                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                                        {i?.contact && <PermContactCalendarOutlined sx={{ color: 'gray', height: 16, width: 16 }} />}
                                                                        <Typography variant='body2'>{i?.contact ? i?.contact?.name : i?.sender_name}</Typography>
                                                                    </Stack>
                                                                    <Typography variant='caption' color={'gray'}>+{i?.sender_mobile}</Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                })}
                                            </Stack>
                                        </Box>
                                        : <Typography variant='body2' color={'gray'} >{data.noChatFound}</Typography>}
                                </Grid>

                                <Grid xs={12} sm={12} md={6} lg={6} item>

                                    <Box mb={4} >
                                        <Typography fontWeight={600} >{data.selectChatFlow}</Typography>
                                    </Box>

                                    <Box overflow={'auto'} height={350} >
                                        <Stack direction={'column'} spacing={2}>
                                            {chatFlow?.map((i, key) => {
                                                return (
                                                    <Stack key={key} i={i} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                        <Stack direction={'row'} spacing={1}>
                                                            <Radio
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setSelFlow(i)
                                                                    }
                                                                }}
                                                                checked={selFlow?.id === i?.id ? true : false}
                                                            />
                                                            <Stack direction={'column'}>
                                                                <Typography variant='body2'>{i?.title}</Typography>
                                                                <Typography variant='caption' color={'gray'}>{moment(i?.createdAt).fromNow()}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                )
                                            })}
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Stack direction={'row'} justifyContent={'flex-end'}>
                                <Tooltip title={data.addChatbot} >
                                    <IconButton
                                        onClick={state?.editing ? updateChatbot : addChatbot}
                                        disabled={state.title && selChat.length > 0 && selFlow ? false : true}
                                        size='large'>
                                        <ArrowCircleRightOutlined
                                            sx={{ color: (t) => state.title && selChat.length > 0 && selFlow && t.palette.primary.main, height: 40, width: 40 }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    </Zoom>}
            </Box>
        </div >
    )
}

export default CreateChatbot