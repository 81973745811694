import { Fitbit, HelpOutline } from '@mui/icons-material'
import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'

const Faq = ({ data, screenWidth }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        faq: []
    })

    async function getFaq() {
        const res = await hitAxios({
            path: "/api/admin/get_faq",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setState({ ...state, faq: res.data.data, question: "", answer: "" })
        }
    }

    React.useEffect(() => {
        getFaq()
    }, [])

    return (
        <div>
            <Box bgcolor={'#fcecfe'} pb={screenWidth > 499 ? 6 : 3} pt={screenWidth > 499 ? 6 : 3}  >

                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={5}>
                        <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <Fitbit sx={{ color: '#bc3dcc', height: 40, width: 40 }} />
                            <Typography fontWeight={600} variant={screenWidth > 699 ? 'h4' : 'h5'} >
                                {data.faqTitle}
                            </Typography>
                        </Stack>

                        <Stack direction={'column'} spacing={3}>
                            {state.faq?.map((i, key) => {
                                return (
                                    <>
                                        <Stack key={key} direction={'column'} spacing={1}>
                                            <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                    <HelpOutline />
                                                    <Typography variant='h6' fontWeight={600} >
                                                        {i?.question}
                                                    </Typography>
                                                </Stack>
                                            </Stack>

                                            <Typography fontWeight={370} >
                                                {i?.answer}
                                            </Typography>
                                        </Stack>
                                        <Divider />
                                    </>
                                )
                            })}
                        </Stack>

                    </Stack>
                </Container>
            </Box>
        </div>
    )
}

export default Faq