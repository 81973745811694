import React from 'react'
import { GlobalContext } from '../context/GlobalContext'
import { TranslateContext } from '../context/TranslateContext'
import Header from '../frontend/components/Header'
import { Alert, Box, Button, CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import TextFieldNew from '../components/TextFieldNew'
import Footer from '../frontend/components/Footer'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { Cancel, Check } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

const RecoveryAdminEmail = (props) => {
    const token = props.match.params.id
    const history = useHistory()
    const { hitAxios } = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [web, setWeb] = React.useState({})
    const [state, setState] = React.useState({

    })

    async function getWebPublic() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setWeb({ ...res.data.data })
        }
    }

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    React.useEffect(() => {
        getWebPublic()
    }, [])

    async function changePass() {
        setState({ ...state, load: true })
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/modify_password?pass=${state.pass}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
        if (!res.data.success) {
            setState({ ...state, msg: res.data.msg, load: false })
            return
        }

        if (res.data.success) {
            setTimeout(() => {
                history.push("/admin")
            }, 3000);
        }
    }

    return (
        web?.id &&
        <div>
            <Header
                screenWidth={screenWidth}
                data={data}
            />

            <Container maxWidth='xs'>
                <Grid container minHeight={'80vh'} alignItems={'center'} justifyContent={'center'}>
                    <Grid xs={12} sm={12} lg={12} item>
                        <Box border={1} p={3} borderRadius={3} >
                            <Stack alignItems={'center'} direction={'column'} spacing={2}>
                                <CardMedia
                                    sx={{ borderRadius: 3, height: "100%", width: 100 }}
                                    component={'img'}
                                    src={`/media/${web?.logo}`}
                                />

                                <Typography variant='h3' >
                                    🤫
                                </Typography>
                                <Typography fontWeight={'bold'} >
                                    {data.passwordRecovery}
                                </Typography>

                                <TextFieldNew
                                    onChange={(e) => setState({ ...state, pass: e.target.value })}
                                    label={data.enterNewPass}
                                    fullWidth
                                />

                                <LoadingButton
                                    loading={state.load}
                                    onClick={changePass}
                                    disabled={state.pass ? false : true}
                                    fullWidth
                                    variant='contained' sx={{ boxShadow: 0, borderRadius: 2 }} >
                                    {data.submit}
                                </LoadingButton         >

                                {state.msg &&
                                    <Alert icon={<Cancel fontSize="inherit" />} severity="error">
                                        {state.msg}
                                    </Alert>}
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Footer
                screenWidth={screenWidth}
                data={data}
            />
        </div>
    )
}

export default RecoveryAdminEmail