import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Link from '@mui/material/Link';
import { CardMedia, ListItemButton, ListItemIcon, ListItemText, Paper, Stack } from '@mui/material'
import { AppSettingsAlt, MonetizationOnOutlined, Dashboard as DashboardIcon, DashboardOutlined, MonetizationOn, Group, GroupOutlined, CreditCard, CreditCardOutlined, Handshake, HandshakeOutlined, Quiz, QuizOutlined, AutoStories, AutoStoriesOutlined, ThumbUp, ThumbUpOutlined, Paid, PaidOutlined, ConnectWithoutContact, ConnectWithoutContactOutlined, AppSettingsAltOutlined, AlternateEmail, AlternateEmailOutlined, Translate, TranslateOutlined, } from '@mui/icons-material'
import DashboardPage from './DashboardPage';
import { useHistory } from 'react-router-dom';
import HeaderAdmin from './HeaderAdmin';
import { TranslateContext } from '../context/TranslateContext'
import { GlobalContext } from '../context/GlobalContext';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://codeyon.com/">
                Codeyon
            </Link>{' '}
            {new Date().getFullYear()}
            {' | '}
            {'Version: 4.9.0'}
        </Typography>
    );
}

const drawerWidth = 250;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


function AdminDashboard() {
    const [open, setOpen] = React.useState(window.innerWidth < 950 ? false : true);
    const history = useHistory()
    const [web, setWeb] = React.useState({})
    const { hitAxios } = React.useContext(GlobalContext)

    async function getWebPublic() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    React.useEffect(() => {
        getWebPublic()
    }, [])


    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('page');

    const T = React.useContext(TranslateContext)

    React.useEffect(() => {
        setPage(parseInt(foo) || 0)
    }, [foo])

    const [page, setPage] = React.useState(null)
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const menudata = [
        {
            name: T.data.dashboard,
            icon: <DashboardIcon sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <DashboardOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.managePLan,
            icon: <MonetizationOn sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <MonetizationOnOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.manageUsers,
            icon: <Group sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <GroupOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.payGateway,
            icon: <CreditCard sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <CreditCardOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.fronPartners,
            icon: <Handshake sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <HandshakeOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.faq,
            icon: <Quiz sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <QuizOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.managePage,
            icon: <AutoStories sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <AutoStoriesOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.testimonial,
            icon: <ThumbUp sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <ThumbUpOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.order,
            icon: <Paid sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <PaidOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.leads,
            icon: <ConnectWithoutContact sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <ConnectWithoutContactOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.appConfig,
            icon: <AppSettingsAlt sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <AppSettingsAltOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.SMTP,
            icon: <AlternateEmail sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <AlternateEmailOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
        {
            name: T.data.webTranslation,
            icon: <Translate sx={{ height: 20, width: 20, color: (t) => "white" }} />,
            iconOutlined: <TranslateOutlined sx={{ height: 20, width: 20, color: "white" }} />
        },
    ]

    function convertHexToRgba(hexCode, opacity) {
        hexCode = hexCode.replace("#", "");
        var r = parseInt(hexCode.substring(0, 2), 16);
        var g = parseInt(hexCode.substring(2, 4), 16);
        var b = parseInt(hexCode.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }

    return (
        <Box
            sx={{ backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex' }}>
            <CssBaseline />
            <Box sx={{ bgcolor: (e) => e.palette.primary.main }} >
                <Drawer
                    key="1"
                    sx={{
                        "& .MuiDrawer-paper": { borderWidth: 0 },
                    }}
                    variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            px: [1],
                            bgcolor: (e) => e.palette.primary.main,
                            // color: 'white'
                        }}
                    >
                        {open && web && <Stack direction='row'>
                            <CardMedia sx={{ borderRadius: 2 }} component='img' height={40} src={`/media/${web.logo}`} /></Stack>}
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon sx={{ color: "white" }} />
                        </IconButton>
                    </Toolbar>
                    <Divider />

                    <List sx={{ bgcolor: (e) => e.palette.primary.main }} component="nav">
                        {
                            menudata.map((item, key) => {
                                return (
                                    <>
                                        {item.divider && <Box p={1.5}>
                                            <Typography color={item.newColor ? item.newColor : "gray"} variant='caption' >{item.dividerTitle} {item.newColor && T.data.new}</Typography>
                                        </Box>}
                                        <ListItemButton sx={{ bgcolor: page === key ? "action.selected" : null, borderRadius: 2, height: 43 }} onClick={() => {
                                            history.push(`/admin?page=${key}`)
                                            setPage(key)
                                        }} key={key} >
                                            <ListItemIcon >
                                                {parseInt(foo || 0) === parseInt(key) ? item.icon : item.iconOutlined}
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography
                                                sx={{ color: (t) => page === key ? "white" : "white", fontWeight: page === key ? 600 : 300 }}
                                                variant='body2' >{item.name}</Typography>} />
                                        </ListItemButton>
                                    </>
                                )
                            })
                        }
                    </List>
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    // background: "#f3fff7",
                    // background: "linear-gradient(to bottom right, #f3fff7, #fbfbfc)"
                }}
            >
                <HeaderAdmin title={T.data.welcome_admin} />
                <Container maxWidth="xl">
                    <DashboardPage page={parseInt(page)} />
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
    );
}

export default function Dashboard() {
    return <AdminDashboard />;
}