import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import SlideShow from '../../components/SlideShow'
import { GlobalContext } from '../../context/GlobalContext'

const HeroOne = ({ data, screenWidth }) => {
    const [brands, setbrands] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)

    async function getBrands() {
        const res = await hitAxios({
            path: "/api/admin/get_brands",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setbrands(res.data.data)
        }
    }

    React.useEffect(() => {
        getBrands()
    }, [])

    return (
        <Box mt={screenWidth > 899 ? 0 : 10} bgcolor={'#fcecfe'} p={5} >
            <Container maxWidth='lg'>
                <Stack direction={'column'} spacing={3}>
                    <Typography
                        fontWeight={600}
                        variant='h5'
                        align='center' >
                        <a style={{ color: data.color_front }} >{data.trusted}</a> {data.heroOneTitle}
                    </Typography>

                    <SlideShow
                        data={data}
                        images={brands}
                    />
                </Stack>
            </Container>
        </Box>
    )
}

export default HeroOne