import { Box, Button, Chip, Dialog, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React from 'react'
import CloseBtn from '../context/CloseBtn'
import TextFieldNew from '../components/TextFieldNew'
import { AccessTime, AccessTimeFilled, CancelOutlined, CheckCircleOutlineRounded, CheckOutlined, DatasetLinked, PermContactCalendar, SmartToy, SpeakerNotes, Star, Stars, StarsOutlined, Style, Update, UpdateRounded } from '@mui/icons-material'
import { GlobalContext } from '../context/GlobalContext'
import { LoadingButton } from '@mui/lab'
import moment from 'moment-timezone'
import momentTime from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ProfileDialog = ({ data, states, setStates }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)
    const [timeZones, setTimeZones] = React.useState([])
    const history = useHistory()
    const [profile, setProfile] = React.useState({

    })

    const [apiKeys, setApiKeys] = React.useState({
    })

    async function getProfile() {
        const res = await hitAxios({
            path: "/api/user/get_me",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setProfile({ ...res.data.data })
        }
    }

    function getAllTimezones() {
        try {
            const timezones = moment.tz.names();
            setTimeZones(timezones)
        } catch (error) {
            return `Error: ${error.message}`;
        }
    }

    async function getKeys() {
        const res = await hitAxios({
            path: "/api/user/get_meta_keys",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setApiKeys({ ...res.data.data })
        }
    }

    React.useEffect(() => {
        getKeys()
        getProfile()
        getAllTimezones()
    }, [states.dialog])

    async function updteAPi() {
        const res = await hitAxios({
            path: "/api/user/update_meta",
            post: true,
            admin: false,
            obj: apiKeys
        })
    }

    async function updateProfile() {
        const res = await hitAxios({
            path: "/api/user/update_profile",
            post: true,
            admin: false,
            obj: profile
        })
    }

    return (
        <Dialog
            fullWidth
            // maxWidth={states?.dialogType === "SUBSCRIPTION" ? 'lg' : 'sm'}
            PaperProps={{
                style: { borderRadius: 10 }
            }}
            onClose={() => {
                setStates({ ...states, dialog: false })
            }}
            open={states?.dialog}>
            <Box p={2}>
                <Stack mb={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography
                        fontWeight={600}
                        variant='h7'
                    >
                        {states?.dialogType === "API_META" && data.metaApiKeys}
                        {states?.dialogType === "PROFILE" && data.profile}
                    </Typography>
                    <CloseBtn
                        onClick={() => setStates({ ...states, dialog: false })}
                    />
                </Stack>

                <Divider />

                <Box mt={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <Chip
                                onClick={() => setStates({ ...states, dialogType: "API_META" })}
                                variant={states.dialogType === "API_META" ? 'filled' : 'outlined'}
                                label={data.metaApiKeys}
                            />

                            <Chip
                                onClick={() => setStates({ ...states, dialogType: "PROFILE" })}
                                variant={states.dialogType === "PROFILE" ? 'filled' : 'outlined'}
                                label={data.profile}
                            />

                            <Chip
                                onClick={() => setStates({ ...states, dialogType: "SUBSCRIPTION" })}
                                variant={states.dialogType === "SUBSCRIPTION" ? 'filled' : 'outlined'}
                                label={data.subscription}
                            />
                        </Stack>

                        {states?.dialogType === "API_META" &&
                            <><TextFieldNew
                                value={apiKeys?.waba_id}
                                onChange={(e) => setApiKeys({ ...apiKeys, waba_id: e.target.value })}
                                label={data.waBAID}
                                fullWidth
                            />
                                <TextFieldNew
                                    value={apiKeys?.business_account_id}
                                    onChange={(e) => setApiKeys({ ...apiKeys, business_account_id: e.target.value })}
                                    label={data.businessAcID}
                                    fullWidth
                                />
                                <TextFieldNew
                                    value={apiKeys?.access_token}
                                    onChange={(e) => setApiKeys({ ...apiKeys, access_token: e.target.value })}
                                    helperText={data.permTokenRecm}
                                    label={data.MetaAccessToken}
                                    fullWidth
                                />
                                <TextFieldNew
                                    value={apiKeys?.business_phone_number_id}
                                    onChange={(e) => setApiKeys({ ...apiKeys, business_phone_number_id: e.target.value })}
                                    label={data.metaWaPhoneID}
                                    fullWidth
                                />
                                <TextFieldNew
                                    value={apiKeys?.app_id}
                                    onChange={(e) => setApiKeys({ ...apiKeys, app_id: e.target.value })}
                                    label={data.appId}
                                    fullWidth
                                />

                                <LoadingButton
                                    loading={CON?.data?.loading}
                                    onClick={updteAPi}
                                    startIcon={<UpdateRounded />}
                                    sx={{ borderRadius: 2, boxShadow: 0 }}
                                    variant='contained'
                                >
                                    {data.save}
                                </LoadingButton>
                            </>
                        }


                        {states?.dialogType === "PROFILE" &&
                            <>
                                <TextFieldNew
                                    onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                                    shrink
                                    label={data.name}
                                    fullWidth
                                    value={profile.name}
                                />

                                <TextFieldNew
                                    onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                                    shrink
                                    label={data.email}
                                    fullWidth
                                    value={profile.email}
                                />

                                <TextFieldNew
                                    onChange={(e) => setProfile({ ...profile, mobile: e.target.value })}
                                    shrink
                                    label={data.mobile}
                                    fullWidth
                                    value={profile.mobile_with_country_code}
                                />

                                <FormControl fullWidth>
                                    <InputLabel size='small' id="timezone">{data.selTimezone}</InputLabel>
                                    <Select
                                        sx={{ borderRadius: 2 }}
                                        size='small'
                                        labelId="timezone"
                                        id="timezone"
                                        value={profile.timezone}
                                        label={data.selTimezone}
                                    >
                                        {timeZones.map((i, key) => {
                                            return (
                                                <MenuItem
                                                    onClick={() => setProfile({ ...profile, timezone: i })}
                                                    key={key} value={i}>
                                                    <Typography variant='body2' >{i}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                <TextFieldNew
                                    onChange={(e) => setProfile({ ...profile, newPassword: e.target.value })}
                                    label={data.password}
                                    helperText={data.ignorePass}
                                    fullWidth
                                    value={profile.newPassword}
                                />

                                <LoadingButton
                                    loading={CON?.data?.loading}
                                    onClick={updateProfile}
                                    startIcon={<UpdateRounded />}
                                    sx={{ borderRadius: 2, boxShadow: 0 }}
                                    variant='contained'
                                >
                                    {data.save}
                                </LoadingButton>
                            </>
                        }

                        {states?.dialogType === "SUBSCRIPTION" &&
                            <>
                                {profile?.plan && <Stack direction={'row'} spacing={1}>
                                    <Stars sx={{ color: "orange" }} />
                                    <Typography fontWeight={600} >
                                        {data.youHaveSubsTo} <a
                                            style={{ color: data.color_theme }}
                                        >{JSON.parse(profile?.plan)?.title}</a>
                                    </Typography>
                                </Stack>}

                                {profile?.plan &&
                                    <Box p={2} bgcolor={'action.hover'} borderRadius={2}>
                                        <Stack direction={'column'} spacing={2}>
                                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                    <Style />
                                                    <Typography>{data.chatTags}</Typography>
                                                </Stack>

                                                {JSON.parse(profile?.plan)?.allow_tag > 0 ?
                                                    <CheckCircleOutlineRounded
                                                        sx={{ color: "green" }}
                                                    /> : <CancelOutlined
                                                        sx={{ color: "red" }}
                                                    />}
                                            </Stack>

                                            <Divider />

                                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                    <SpeakerNotes />
                                                    <Typography>{data.chatNotes}</Typography>
                                                </Stack>

                                                {JSON.parse(profile?.plan)?.allow_note > 0 ?
                                                    <CheckCircleOutlineRounded
                                                        sx={{ color: "green" }}
                                                    /> : <CancelOutlined
                                                        sx={{ color: "red" }}
                                                    />}
                                            </Stack>

                                            <Divider />

                                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                    <SmartToy />
                                                    <Typography>{data.autoChatbot}</Typography>
                                                </Stack>

                                                {JSON.parse(profile?.plan)?.allow_chatbot > 0 ?
                                                    <CheckCircleOutlineRounded
                                                        sx={{ color: "green" }}
                                                    /> : <CancelOutlined
                                                        sx={{ color: "red" }}
                                                    />}
                                            </Stack>

                                            <Divider />

                                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                    <PermContactCalendar />
                                                    <Typography>{data.phoneBookContactsLImit}</Typography>
                                                </Stack>

                                                <Typography fontWeight={600} >
                                                    {profile?.contact}/{JSON.parse(profile?.plan)?.contact_limit}
                                                </Typography>
                                            </Stack>

                                            <Divider />

                                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                    <DatasetLinked />
                                                    <Typography>{data.apiAccess}</Typography>
                                                </Stack>

                                                {JSON.parse(profile?.plan)?.allow_api > 0 ?
                                                    <CheckCircleOutlineRounded
                                                        sx={{ color: "green" }}
                                                    /> : <CancelOutlined
                                                        sx={{ color: "red" }}
                                                    />}
                                            </Stack>

                                            <Divider />

                                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                    <AccessTime />
                                                    <Typography>{data.planDuration}</Typography>
                                                </Stack>

                                                <Typography fontWeight={600} >
                                                    {JSON.parse(profile?.plan)?.plan_duration_in_days}
                                                </Typography>
                                            </Stack>

                                            <Divider />

                                            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                    <AccessTimeFilled />
                                                    {profile?.plan_expire ? (
                                                        <Typography>
                                                            {momentTime
                                                                .unix(profile.plan_expire / 1000)
                                                                .isBefore(moment()) ?
                                                                data.planExpiredON : data.planDaysLeft}
                                                        </Typography>
                                                    ) : <Typography>{data.planExpiredON}</Typography>}
                                                </Stack>
                                                {profile?.plan_expire ? (
                                                    <Typography fontWeight={600}>
                                                        {momentTime.unix(profile.plan_expire / 1000).fromNow()}
                                                    </Typography>
                                                ) : <Typography fontWeight={'bold'} color={'red'} >{data.planExpired}</Typography>}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                }
                            </>
                        }

                        <Button
                            onClick={() => {
                                history.push('/')
                            }}
                            variant='contained'
                            color='info'
                            startIcon={<Star />}
                            sx={{ boxShadow: 0, borderRadius: 2 }}
                            size='large'
                        >
                            {data.checkAllPlans}
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    )
}

export default ProfileDialog