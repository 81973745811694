import { Box, Container, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'
import UserDash from './UserDash'
import { AccountTree, Facebook, PermContactCalendar, Podcasts, QuestionAnswer, SmartToy } from '@mui/icons-material'
import ChatBots from './ChatBots'

const Dashboard = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    const [dash, setDash] = React.useState({})

    async function getDash() {
        const res = await G.hitAxios({
            path: "/api/user/get_dashboard",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setDash(res.data)
        }
    }

    React.useEffect(() => {
        getDash()
    }, [])

    return (
        <div>
            <Container maxWidth='lg'>
                <Box minHeight={'40vh'} mt={2} borderRadius={2} bgcolor={'white'} p={2}>
                    {G.data.loading ?
                        <Stack height={'40vh'} >
                            {Array(5).fill(null).map((_, key) => (
                                <Skeleton height={53} key={key} animation="wave" />
                            ))}
                        </Stack> :
                        dash?.opened && dash?.pending && dash?.resolved &&
                        <UserDash
                            data={data}
                            dash={dash} />
                    }
                </Box>

                <Grid container spacing={2} >
                    <Grid xs={12} lg={6} sm={6} item>
                        <Box height={'37vh'} mt={2} borderRadius={2} bgcolor={'white'} p={2}>
                            {G.data.loading ?
                                <Stack height={'37vh'} >
                                    {Array(5).fill(null).map((_, key) => (
                                        <Skeleton height={53} key={key} animation="wave" />
                                    ))}
                                </Stack> :
                                dash?.opened && dash?.pending && dash?.resolved &&
                                <ChatBots
                                    data={data}
                                    dash={dash} />
                            }
                        </Box>
                    </Grid>
                    <Grid xs={12} lg={6} sm={6} item>
                        <Box
                            overflow={'auto'}
                            height={'37vh'} p={2} mt={2} borderRadius={2} bgcolor={'white'}>
                            <Stack direction={'column'} spacing={2}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                        <QuestionAnswer sx={{ height: 18, width: 18 }} />
                                        <Typography variant='body2' >{data.totalChats}</Typography>
                                    </Stack>
                                    <Typography variant='body2' >{dash?.totalChats || '🧐'}</Typography>
                                </Stack>

                                <Divider />

                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                        <SmartToy sx={{ height: 18, width: 18 }} />
                                        <Typography variant='body2' >{data.totalChatbots}</Typography>
                                    </Stack>
                                    <Typography variant='body2' >{dash?.totalChatbots || '🧐'}</Typography>
                                </Stack>

                                <Divider />

                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                        <PermContactCalendar sx={{ height: 18, width: 18 }} />
                                        <Typography variant='body2' >{data.totalContacts}</Typography>
                                    </Stack>
                                    <Typography variant='body2' >{dash?.totalContacts || '🧐'}</Typography>
                                </Stack>

                                <Divider />

                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                        <AccountTree sx={{ height: 18, width: 18 }} />
                                        <Typography variant='body2' >{data.totalFlows}</Typography>
                                    </Stack>
                                    <Typography variant='body2' >{dash?.totalFlows || '🧐'}</Typography>
                                </Stack>

                                <Divider />

                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                        <Podcasts sx={{ height: 18, width: 18 }} />
                                        <Typography variant='body2' >{data.totalCampaign}</Typography>
                                    </Stack>
                                    <Typography variant='body2' >{dash?.totalBroadcast || '🧐'}</Typography>
                                </Stack>

                                <Divider />

                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                        <Facebook sx={{ height: 18, width: 18 }} />
                                        <Typography variant='body2' >{data.totalMetaTemplets}</Typography>
                                    </Stack>
                                    <Typography variant='body2' >{dash?.totalTemplets || '🧐'}</Typography>
                                </Stack>

                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Dashboard