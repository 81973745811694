import { Delete, DeleteOutline, Edit, SmartToy } from '@mui/icons-material'
import { Box, Divider, Grid, IconButton, Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


const ChatbotList = ({ data, screenWidth, chatbotList, setChatbotList, chats, getChatbots, chatFlow, state, setState, hitAxios, setSelChat, selChat, selFlow, setSelFlow }) => {

    async function changeBotStatus(id, status) {
        const res = await hitAxios({
            path: "/api/chatbot/change_bot_status",
            post: true,
            admin: false,
            obj: { id, status }
        })
        if (res.data.success) {
            getChatbots()
        }
    }

    async function delChatbot(id) {
        if (window.confirm(data.AUS)) {
            const res = await hitAxios({
                path: "/api/chatbot/del_chatbot",
                post: true,
                admin: false,
                obj: { id }
            })
            if (res.data.success) {
                getChatbots()
            }
        }
    }


    return (
        <div>
            <Box m={3} p={2} bgcolor={'white'} borderRadius={2}>
                <Stack direction={'column'} spacing={2}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <SmartToy
                            sx={{ color: "#7C7C7C" }}
                        />
                        <Typography variant='h6' color={'#7C7C7C'} fontWeight={600} >{data.chatbotList}</Typography>
                    </Stack>

                    {chatbotList ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={[...chatbotList].reverse()}
                                columns={[
                                    { headerName: data.id, field: 'id', flex: 1 },
                                    { headerName: data.title, field: 'title', flex: 1 },
                                    {
                                        headerName: data.flowTitle, field: 'flowTitle', flex: 1, renderCell: dataa => {
                                            return (
                                                dataa?.row?.title
                                            )
                                        }
                                    },
                                    {
                                        headerName: data.isActive, field: 'active', flex: 1, renderCell: dataa => {
                                            return (
                                                <Switch
                                                    onChange={(e) => changeBotStatus(dataa.row?.id, e.target.checked)}
                                                    size='small'
                                                    checked={dataa.row.active} />
                                            )
                                        }
                                    },
                                    {
                                        headerName: data.edit, field: 'edit', flex: 1, renderCell: dataa => {
                                            return (
                                                <IconButton
                                                    onClick={() => {
                                                        setState({
                                                            ...state,
                                                            title: dataa?.row?.title,
                                                            botId: dataa?.row?.id,
                                                            for_all: dataa?.row?.for_all > 0 ? true : false,
                                                            add: true,
                                                            editing: true
                                                        })
                                                        setSelChat(JSON.parse(dataa?.row?.chats))
                                                        setSelFlow(JSON.parse(dataa?.row?.flow))
                                                    }}
                                                    color='info' >
                                                    <Edit />
                                                </IconButton>
                                            )
                                        }
                                    },
                                    {
                                        headerName: data.delete, field: 'del', flex: 1, renderCell: dataa => {
                                            return (
                                                <IconButton
                                                    onClick={() => delChatbot(dataa.row?.id)}
                                                    color='error' >
                                                    <DeleteOutline />
                                                </IconButton>
                                            )
                                        }
                                    }
                                ]}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={false}
                                // components={{
                                //     Toolbar: CustomToolbar
                                // }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Stack>
            </Box>
        </div>
    )
}

export default ChatbotList