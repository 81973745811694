import { Box, Stack, Typography } from '@mui/material';
import React from 'react'
import { Adjust, FormatListBulleted, Image } from '@mui/icons-material'

const ChatListMsgType = ({ i, data }) => {
    function parseJSON(jsonString) {
        try {
            const parsedJson = JSON.parse(jsonString);
            return parsedJson;
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return {}; // Return empty object if parsing fails
        }
    }


    return (
        <Box  >
            {parseJSON(i?.last_message)?.type === "image" &&
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Image sx={{ height: 18, width: 18, color: '#808080' }} />
                    <Typography variant='body2' color={'#808080'} fontWeight={500}  >{data?.newImg}</Typography>
                </Stack>}

            {parseJSON(i?.last_message)?.type === "text" &&
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Typography variant='body2' color={'#808080'} fontWeight={500}  >
                        {parseJSON(i?.last_message)?.msgContext &&
                            parseJSON(i?.last_message)?.msgContext?.text &&
                            parseJSON(i?.last_message)?.msgContext?.text?.body?.length > 80 ?
                            parseJSON(i?.last_message)?.msgContext?.text?.body?.slice(0, 80) + "..." :
                            parseJSON(i?.last_message)?.msgContext?.text?.body}
                    </Typography>
                </Stack>}

            {parseJSON(i?.last_message)?.type === "audio" &&
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Image sx={{ height: 18, width: 18, color: '#808080' }} />
                    <Typography variant='body2' color={'#808080'} fontWeight={500}  >{data?.newAudio}</Typography>
                </Stack>}

            {parseJSON(i?.last_message)?.type === "document" &&
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Image sx={{ height: 18, width: 18, color: '#808080' }} />
                    <Typography variant='body2' color={'#808080'} fontWeight={500}  >{data?.newDoc}</Typography>
                </Stack>}

            {parseJSON(i?.last_message)?.type === "button" &&
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Adjust sx={{ height: 18, width: 18, color: '#808080' }} />
                    <Typography variant='body2' color={'#808080'} fontWeight={500}  >{data?.newButton}</Typography>
                </Stack>}

            {parseJSON(i?.last_message)?.type === "list" &&
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <FormatListBulleted sx={{ height: 18, width: 18, color: '#808080' }} />
                    <Typography variant='body2' color={'#808080'} fontWeight={500}  >{data?.newList}</Typography>
                </Stack>}
        </Box>
    )
}

export default ChatListMsgType