import { Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'
import { Article, Check, DoneAll, Download } from '@mui/icons-material'

const DocMsgOutgoing = ({ i, data, notShow, chatConvo }) => {

    return (
        <div id={i?.metaChatId} >
            <Stack alignItems={'flex-end'} direction={'row'} justifyContent={'flex-end'}>

                {!notShow &&
                    <Box ml={0.6} mb={1}>
                        <Stack spacing={0.5} alignItems={'center'} direction={'row'}>
                            <Typography fontSize={10} color={'gray'}>
                                {moment.unix(i?.timestamp).format("DD/MM/YYYY hh:mm A")}
                            </Typography>

                            {!i?.status && <Check sx={{ height: 18, width: 18 }} />}
                            {i?.status === "delivered" && <DoneAll sx={{ eight: 18, width: 18 }} />}
                            {i?.status === "read" && <DoneAll sx={{ eight: 18, width: 18, color: "#4CA340" }} />}
                        </Stack>
                    </Box>}
                <Box width={notShow ? '100%' : undefined} maxWidth={!notShow ? 505 : undefined} bgcolor={'#dcf1e5'} p={1.5}
                    sx={{
                        borderTopRightRadius: 20,
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        m: 1
                    }}>

                    <Box sx={{ wordWrap: 'break-word' }} position={'relative'}>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Article sx={{ height: 20, width: 20, color: 'gray' }} />
                            <Typography color={'gray'} variant='body2' fontWeight={500} >{data?.document}</Typography>

                            <IconButton
                                onClick={() => {
                                    window.open(i?.msgContext?.document && i?.msgContext?.document?.link)
                                }}>
                                <Download sx={{ height: 20, width: 20 }} />
                            </IconButton>
                        </Stack>

                        <Typography
                            component={'i'}
                            style={{ whiteSpace: 'pre-line' }}
                            variant='caption' color={'gray'}>
                            {i?.msgContext?.document && i?.msgContext?.document?.caption}
                        </Typography>

                        <Box right={0} position={'absolute'}>
                            <Box sx={{ cursor: 'pointer' }} >
                                <Typography variant='h7' >{i?.reaction}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Stack>
        </div>
    )
}

export default DocMsgOutgoing