import { Box, Button, CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import ButtonComp from '../../context/ButtonComp'
import { ArrowForwardIos } from '@mui/icons-material'
import './float.css'

const Hero = ({ data, screenWidth }) => {
    const divStyle = {
        // backgroundImage: `url(/assets/bg_hero.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%'
    };
    return (
        <div style={divStyle}>
            <Container maxWidth='lg'>
                {screenWidth > 899 ?
                    <Grid justifyContent={'space-between'} minHeight={'80vh'} alignItems={'center'} container spacing={6}>
                        <Grid xs={12} lg={5.5} md={6} item>
                            <Box pt={5} position={'relative'}>
                                <Stack direction={'column'} spacing={2}>
                                    <Typography variant='h3' fontWeight={'bold'} >
                                        {data.groYourBueinss} <a style={{ color: data.color_front }} >{data.appNamee}</a>
                                    </Typography>

                                    <Typography fontWeight={500} >
                                        {data.heroDes}
                                    </Typography>

                                    <Stack >
                                        <Stack alignItems={'center'} mt={2} direction={'row'} spacing={2}>
                                            <ButtonComp
                                                bgcolor={data.color_front}
                                                borderWeight={2}
                                                fontWeight={600}
                                                color={'black'}
                                                title={data.tryNow}
                                                fullWidth
                                            />
                                            <Button
                                                color='inherit'
                                                endIcon={<ArrowForwardIos />}
                                                sx={{ textTransform: 'none' }}
                                                fullWidth>
                                                {data.orBookDemo}</Button>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Box position={'absolute'} top={0} >
                                    <CardMedia
                                        sx={{ width: '100%', height: 50 }}
                                        component={'img'}
                                        src="assets/herotop.png"
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid xs={12} lg={6} md={6} item>
                            <CardMedia
                                // className='floating'
                                sx={{ height: "100%", width: "100%" }}
                                component={'img'}
                                src="/assets/hero.png"
                            />
                        </Grid>
                    </Grid> :

                    <Grid sx={{ mt: 0.5 }} justifyContent={'space-between'} minHeight={'80vh'} alignItems={'center'} container spacing={6}>
                        <Grid xs={12} lg={6} md={6} item>
                            <CardMedia
                                sx={{ height: "100%", width: "100%" }}
                                component={'img'}
                                src="/assets/hero.png"
                            />
                        </Grid>

                        <Grid xs={12} lg={5} md={6} item>
                            <Box pt={5} position={'relative'}>
                                <Stack direction={'column'} spacing={2}>
                                    <Typography variant='h3' fontWeight={600} >
                                        {data.groYourBueinss} <a style={{ color: data.color_front }} >{data.appNamee}</a>
                                    </Typography>

                                    <Typography fontWeight={500} >
                                        {data.heroDes}
                                    </Typography>

                                    <Stack >
                                        <Stack alignItems={'center'} mt={2} direction={'row'} spacing={2}>
                                            <ButtonComp
                                                bgcolor={data.color_front}
                                                borderWeight={2}
                                                fontWeight={600}
                                                color={'black'}
                                                title={data.tryNow}
                                                fullWidth
                                            />
                                            <Button
                                                color='inherit'
                                                endIcon={<ArrowForwardIos />}
                                                sx={{ textTransform: 'none' }}
                                                fullWidth>
                                                {data.orBookDemo}</Button>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Box position={'absolute'} top={0} >
                                    <CardMedia
                                        sx={{ width: '100%', height: 50 }}
                                        component={'img'}
                                        src="assets/herotop.png"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>}
            </Container>
        </div >
    )
}

export default Hero