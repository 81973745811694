import { Box, CardActionArea, CardMedia, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'
import { Download, DownloadOutlined } from '@mui/icons-material'
import TextMsg from './TextMsg'
import AudioMsg from './AudioMsg'
import DocMsg from './DocMsg'
import VideoMsg from './VideoMsg'
import { format } from '@flasd/whatsapp-formatting';
import ButtonMsgOutgoing from './outgoing/ButtonMsgOutgoing'
import ListMsgOutgoing from './outgoing/ListMsgOutgoing'

const ImageMsg = ({ i, data, notShow, chatConvo }) => {
    function returnContextMsg(id) {
        if (!id || !chatConvo || !i?.context) return {};
        const getMsg = chatConvo.filter(ii => ii.metaChatId === id);
        if (getMsg?.length > 0) {
            return getMsg[0]
        } else {
            return {};
        }
    }

    function scrollToMessage(id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    return (
        <div id={i?.metaChatId} >
            <Stack alignItems={'flex-end'} direction={'row'} >
                <Box width={notShow ? '100%' : undefined} maxWidth={!notShow ? 505 : undefined} bgcolor={'white'} p={1.5}
                    sx={{
                        borderBottomRightRadius: 20,
                        borderBottomLeftRadius: 20,
                        borderTopRightRadius: 20,
                        m: 1
                    }}>
                    {i?.context &&
                        <Box
                            onClick={() => scrollToMessage(i.context.id)}
                            sx={{ cursor: 'pointer' }}
                            mb={1} bgcolor={'#DCDCDC'} borderRadius={2} >
                            {returnContextMsg(i?.context?.id)?.type === "text" && <TextMsg notShow={true} i={returnContextMsg(i?.context?.id)} data={data} />}
                            {returnContextMsg(i?.context?.id)?.type === "image" && <ImageMsg notShow={true} i={returnContextMsg(i?.context?.id)} data={data} />}
                            {returnContextMsg(i?.context?.id)?.type === "audio" && <AudioMsg notShow={true} i={returnContextMsg(i?.context?.id)} data={data} />}
                            {returnContextMsg(i?.context?.id)?.type === "document" && <DocMsg notShow={true} i={returnContextMsg(i?.context?.id)} data={data} />}
                            {returnContextMsg(i?.context?.id)?.type === "video" && <VideoMsg notShow={true} i={returnContextMsg(i?.context?.id)} data={data} />}
                            {returnContextMsg(i?.context?.id)?.type === "button" && <ButtonMsgOutgoing notShow={true} i={returnContextMsg(i?.context?.id)} data={data} />}
                            {returnContextMsg(i?.context?.id)?.type === "list" && <ListMsgOutgoing notShow={true} i={returnContextMsg(i?.context?.id)} data={data} />}
                        </Box>}

                    <Box sx={{ wordWrap: 'break-word' }} position={'relative'}>
                        <Stack spacing={1} direction={'column'}>

                            <CardMedia
                                loading='lazy'
                                sx={{ borderRadius: 2 }}
                                height={"100%"}
                                width={'100%'}
                                src={i?.msgContext?.image && i?.msgContext?.image?.link} component={'img'} />

                            <Typography
                                style={{ whiteSpace: 'pre-line' }}
                                variant='caption'
                                color={'gray'}>
                                {i?.msgContext?.image && i?.msgContext?.image?.caption}
                            </Typography>


                        </Stack>
                        <Box position={'absolute'} top={0} right={0}>
                            <IconButton
                                onClick={() => {
                                    window.open(i?.msgContext?.image && i?.msgContext?.image?.link)
                                }}
                                size='small'
                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} >
                                <DownloadOutlined />
                            </IconButton>
                        </Box>
                        <Box right={0} position={'absolute'}>
                            <Box sx={{ cursor: 'pointer' }} >
                                <Typography variant='h7' >{i?.reaction}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {!notShow &&
                    <Box ml={0.6} mb={1}>
                        <Typography fontSize={10} color={'gray'}>
                            {moment.unix(i?.timestamp).format("DD/MM/YYYY hh:mm A")}
                        </Typography>
                    </Box>}
            </Stack>
        </div>
    )
}

export default ImageMsg