import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import moment from 'moment'
import { DeleteOutline, Visibility } from '@mui/icons-material';
import ViewBroadcast from './ViewBroadcast';
import { GlobalContext } from '../../../context/GlobalContext';

const BroadCastList = ({ data, setState, state, broadList, getBroadList }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)
    const [hide, setHide] = React.useState(localStorage.getItem('hide_broadcast_header') ? true : false)
    const [dialog, setDialog] = React.useState({
        open: false
    })
    const [logs, setLogs] = React.useState([])
    const [logStatus, setLogStatus] = React.useState({})

    async function getLogs(log) {
        const res = await hitAxios({
            path: "/api/broadcast/get_broadcast_logs",
            post: true,
            admin: false,
            obj: { id: log?.broadcast_id }
        })
        if (res.data.success) {
            setLogs(res.data.data)
            setLogStatus({ ...res.data, data: "" })
            setDialog({
                open: true,
                openData: log
            })
        }
    }

    async function delBroadcast(broadcast_id) {
        if (window.confirm(data.AUS)) {
            const res = await hitAxios({
                path: "/api/broadcast/del_broadcast",
                post: true,
                admin: false,
                obj: { broadcast_id }
            })
            if (res.data.success) {
                getBroadList()
            }
        }
    }
    return (
        <div>
            <Box mt={2} borderRadius={4} bgcolor={'white'} >
                {broadList ?
                    <Box height={hide ? '76vh' : '48vh'}>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...broadList].reverse()}
                            columns={[
                                { headerName: data.id, field: 'id', flex: 1 },
                                {
                                    headerName: data.title, field: 'title', flex: 1, renderCell: dataa => {
                                        return (
                                            <Tooltip title={dataa.row.title} >
                                                {dataa.row.title}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.templet, field: 'templet', flex: 1, renderCell: dataa => {
                                        return (
                                            <Tooltip title={JSON.parse(dataa.row.templet)?.name} >
                                                {JSON.parse(dataa.row.templet)?.name}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.phonebook, field: 'phonebook', flex: 1, renderCell: dataa => {
                                        return (
                                            <Tooltip title={JSON.parse(dataa.row.phonebook)?.name} >
                                                {JSON.parse(dataa.row.phonebook)?.name}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.status, field: 'status', flex: 1, renderCell: dataa => {
                                        return (
                                            <Tooltip title={dataa.row.status} >
                                                {dataa.row.status}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.date, field: 'schedule', flex: 1, renderCell: dataa => {
                                        return (
                                            <Tooltip title={dataa.row.status} >
                                                {moment(dataa.row.schedule).format("DD-MM-YY | hh:mmA")}
                                            </Tooltip>
                                        )
                                    }
                                },
                                {
                                    headerName: data.edit, field: 'edit', flex: 1, renderCell: dataa => {
                                        return (
                                            <IconButton onClick={() => {
                                                getLogs(dataa?.row)
                                            }} >
                                                <Visibility />
                                            </IconButton>
                                        )
                                    }
                                },
                                {
                                    headerName: data.delete, field: 'del', flex: 1, renderCell: dataa => {
                                        return (
                                            <IconButton
                                                onClick={() => delBroadcast(dataa.row?.broadcast_id)}
                                                color='error' >
                                                <DeleteOutline />
                                            </IconButton>
                                        )
                                    }
                                },
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            // components={{
                            //     Toolbar: CustomToolbar
                            // }}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>

            <ViewBroadcast
                logs={logs}
                setLogs={setLogs}
                dialog={dialog}
                setDialog={setDialog}
                data={data}
                states={state}
                setState={setState}
                hitAxios={hitAxios}
                getBroadList={getBroadList}
                CON={CON}
                logStatus={logStatus}
            />
        </div>
    )
}

export default BroadCastList