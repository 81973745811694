import { Box, Button, Dialog, Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import { LoadingButton } from '@mui/lab'
import CloseBtn from '../../context/CloseBtn'
import TextFieldNew from '../../components/TextFieldNew'
import ButtonComp from '../../context/ButtonComp'
import { GlobalContext } from '../../context/GlobalContext'

const AddPlan = ({ data, state, setState, getPlan }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)
    const [plan, setPlan] = React.useState({
        allow_tag: false,
        allow_note: false,
        allow_chatbot: false,
        allow_api: false,
        contact_limit: 100,
        is_trial: false
    })

    async function addPlan() {
        const res = await hitAxios({
            path: "/api/admin/add_plan",
            post: true,
            admin: true,
            obj: plan
        })
        if (res.data.success) {
            window.location.reload()
        }
    }

    return (
        <div>
            <Dialog
                sx={{ backdropFilter: "blur(10px)" }}
                onClose={() => setState({ ...state, dialog: false })}
                PaperProps={{
                    style: { borderRadius: 10 }
                }}
                open={state.dialog} fullWidth>
                <Box p={3}>
                    <Stack justifyContent="space-between" alignItems={'center'} direction='row'>
                        <Typography variant='h6' >{data.addPlan}</Typography>
                        <CloseBtn onClick={() => setState({ ...state, dialog: false })} />
                    </Stack>

                    <Stack mt={3} direction={'column'} spacing={2}>
                        <TextFieldNew
                            onChange={(e) => setPlan({ ...plan, title: e.target.value })}
                            label={data.planTitle}
                        />

                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='caption' >{data.isThisATrialPlan}</Typography>

                            <Switch checked={plan.is_trial}
                                onChange={(e) => setPlan({ ...plan, is_trial: e.target.checked })}
                                size='small' />
                        </Stack>


                        {!plan.is_trial &&
                            <>
                                <TextFieldNew
                                    onChange={(e) => setPlan({ ...plan, price: e.target.value })}
                                    type='number'
                                    label={data.planPrice}
                                />


                                <TextFieldNew
                                    onChange={(e) => setPlan({ ...plan, price_strike: e.target.value })}
                                    type='number'
                                    label={data.planPriceCrosed}
                                    helperText={<span style={{ textDecoration: 'line-through' }}>{data.itWillShowLike}</span>}
                                />
                            </>}

                        <TextFieldNew
                            onChange={(e) => setPlan({ ...plan, plan_duration_in_days: e.target.value })}
                            type='number'
                            label={data.planDurationInDays}
                        />


                        <TextFieldNew
                            onChange={(e) => setPlan({ ...plan, short_description: e.target.value })}
                            multiline
                            rows={3}
                            label={data.planShortDes}
                        />

                        <TextFieldNew
                            value={plan.contact_limit}
                            onChange={(e) => setPlan({ ...plan, contact_limit: e.target.value })}
                            type='number'
                            label={data.phoneBookContactsLImit}
                        />

                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='caption' >{data.allowChatTags}</Typography>

                            <Switch checked={plan.allow_tag}
                                onChange={(e) => setPlan({ ...plan, allow_tag: e.target.checked })}
                                size='small' />
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='caption' >{data.allowChatNotes}</Typography>

                            <Switch checked={plan.allow_note}
                                onChange={(e) => setPlan({ ...plan, allow_note: e.target.checked })}
                                size='small' />
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='caption' >{data.allowWaChatbot}</Typography>

                            <Switch checked={plan.allow_chatbot}
                                onChange={(e) => setPlan({ ...plan, allow_chatbot: e.target.checked })}
                                size='small' />
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='caption' >{data.allowCloudAPI}</Typography>

                            <Switch checked={plan.allow_api}
                                onChange={(e) => setPlan({ ...plan, allow_api: e.target.checked })}
                                size='small' />
                        </Stack>

                        <LoadingButton
                            loading={CON.data.loading}
                            onClick={addPlan}
                            variant='contained'
                            sx={{ borderRadius: 3, boxShadow: 0 }}
                        >{data.addPlan}</LoadingButton>
                    </Stack>
                </Box>
            </Dialog>
        </div>
    )
}

export default AddPlan