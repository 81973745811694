import axios from 'axios'

function getUser(token) {
    return new Promise((resolve) => {
        const token1 = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get_me`, {
            headers: {
                Authorization: "Bearer " + token1
            }
        }).then((res) => {
            if (res.data?.logout) {
                localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user")
                window.location.reload()
            }
            resolve(res.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    })
}

export { getUser }