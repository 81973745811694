import { Avatar, Box, CardActionArea, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { KeyboardDoubleArrowLeft, Menu } from '@mui/icons-material'
import React from 'react'
import CircularProgressWithLabel from './CircularProgressWithLabel'
import ChattingHere from './ChattingHere'
import moment from 'moment'
import SendBottom from './SendBottom'
import ConversationSide from './ConversationSide'


const Conversation = ({ DRAWER_WIDTH, state, setState, INBOX, socket, chatList, data, CON, chatConvo, getChatList, screenWidth }) => {
    const [value, setValue] = React.useState(0);

    const chatContainerRef = React.useRef(null);


    React.useEffect(() => {
        // Scroll smoothly to the bottom of the chat container when chatConvo changes
        chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth' // Add smooth behavior for scrolling
        });
    }, [chatConvo]);

    function getLastIncomingMessage(messages) {
        let lastIncomingMessage = null;
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].route === "INCOMING") {
                lastIncomingMessage = messages[i];
                break;
            }
        }
        return lastIncomingMessage ? lastIncomingMessage.timestamp : null;
    }

    React.useEffect(() => {
        const lastTimestamp = getLastIncomingMessage(chatConvo || [])

        const calculateTimeLeft = () => {
            if (!lastTimestamp) {
                setValue('No timestamp provided');
                return;
            }

            const lastMoment = moment.unix(lastTimestamp);
            const currentMoment = moment();

            const duration = moment.duration(currentMoment.diff(lastMoment));

            // Check if duration exceeds 24 hours
            if (duration.asHours() >= 24) {
                setValue('Expired');
                return;
            }

            // Calculate remaining time from 24 hours
            const remainingTime = moment.duration(24, 'hours').subtract(duration);

            // Get remaining hours and minutes
            const remainingHours = Math.floor(remainingTime.asHours());
            const remainingMinutes = remainingTime.minutes();

            // Format and set the value
            setValue(`${remainingHours}:${remainingMinutes.toString().padStart(2, '0')}`);
        };

        // Initial calculation
        calculateTimeLeft();

        // Recalculate every minute
        const interval = setInterval(calculateTimeLeft, 60000);

        return () => clearInterval(interval);
    }, [chatConvo]);


    function changeTicket(status) {
        socket.emit('change_ticket_status', { uid: CON.data?.userData?.uid, status, chatId: INBOX?.data?.openedChat?.chat_id });
    }

    const open =
        <Box sx={{ padding: "3px 7px 3px 7px" }} bgcolor={'#eef7e7'} >
            <Typography color={'#5da570'} fontSize={16} fontWeight={600} >🧐 {data.open}</Typography>
        </Box>

    const pending = <Box sx={{ padding: "3px 7px 3px 7px" }} bgcolor={'#fef7da'} >
        <Typography color={'#f5bd1d'} fontSize={16} fontWeight={600} >😅 {data.pending}</Typography>
    </Box>

    const solved =
        <Box sx={{ padding: "3px 7px 3px 7px" }} bgcolor={'#e5f7ff'} >
            <Typography color={'#72b5cf'} fontSize={16} fontWeight={600} >😇 {data.solved}</Typography>
        </Box>

    const ticketComp = <Box>
        <Tooltip title={data.chnageTicketStatus} >
            <CardActionArea onClick={() => changeTicket("solved")} >
                {INBOX?.data?.openedChat?.chat_status === "open" && open}
            </CardActionArea>
        </Tooltip>

        <Tooltip title={data.chnageTicketStatus} >
            <CardActionArea onClick={() => changeTicket("pending")} >
                {INBOX?.data?.openedChat?.chat_status === "solved" && solved}
            </CardActionArea>
        </Tooltip>

        <Tooltip title={data.chnageTicketStatus} >
            <CardActionArea onClick={() => changeTicket("open")} >
                {INBOX?.data?.openedChat?.chat_status === "pending" && pending}
            </CardActionArea>
        </Tooltip>
    </Box>

    return (
        <Box
            sx={{
                width: screenWidth > DRAWER_WIDTH ? screenWidth - 340 : "100%",
            }} >
            <Stack pr={0.5} pl={0.5} height={'92vh'} direction={'column'} justifyContent={'space-between'}>
                <Box p={1} bgcolor={'white'} >
                    <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'} spacing={2}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            {screenWidth < DRAWER_WIDTH &&
                                <IconButton onClick={() => setState({ ...state, drawer: !state.drawer })} >
                                    <Menu />
                                </IconButton>}

                            <Avatar>
                                {INBOX?.data?.openedChat?.sender_name && INBOX?.data?.openedChat?.sender_name?.slice(0, 1)?.toUpperCase()}
                            </Avatar>

                            <Stack direction={'column'}>
                                <Typography fontWeight={600} >
                                    {INBOX?.data?.openedChat?.contact ? INBOX?.data?.openedChat?.contact?.name : INBOX?.data?.openedChat?.sender_name}
                                </Typography>
                                <Typography color={'gray'} variant='caption' fontWeight={500} >
                                    +{INBOX?.data?.openedChat?.sender_mobile && INBOX?.data?.openedChat?.sender_mobile}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack spacing={2} direction={'row'} alignItems={'center'}>
                            {screenWidth > 488 && ticketComp}

                            <CircularProgressWithLabel data={data} value={value} />

                            <Tooltip title={data.openMenu} >
                                <IconButton
                                    onClick={() => setState({ ...state, sideBardDialog: true })}
                                    size='large' >
                                    <KeyboardDoubleArrowLeft sx={{ height: 30, width: 30 }} />
                                </IconButton>
                            </Tooltip>

                            <ConversationSide
                                ticketComp={ticketComp}
                                getChatList={getChatList}
                                DRAWER_WIDTH={DRAWER_WIDTH}
                                state={state}
                                setState={setState}
                                INBOX={INBOX}
                                // socket={socket}
                                chatList={chatList}
                                data={data}
                                CON={CON}
                                chatConvo={chatConvo}
                                screenWidth={screenWidth}
                            />
                        </Stack>
                    </Stack>
                </Box>


                <Box
                    style={{ scrollBehavior: 'smooth' }}
                    height={'100%'} overflow={'auto'} ref={chatContainerRef} >
                    <ChattingHere value={value} data={data} chatConvo={chatConvo} />
                </Box>

                <SendBottom
                    value={value}
                    data={data} chatConvo={chatConvo} INBOX={INBOX} />
            </Stack>
        </Box>
    )
}


export default Conversation