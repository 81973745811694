import { Box } from "@mui/material";
import React from "react";
import TextMsg from "./TextMsg";
import ImageMsg from "./ImageMsg";
import AudioMsg from "./AudioMsg";
import DocMsg from "./DocMsg";
import VideoMsg from "./VideoMsg";
import TextMsgOutgoing from "./outgoing/TextMsgOutgoing";
import AudioMsgOutgoing from "./outgoing/AudioMsgOutgoing";
import DocMsgOutgoing from "./outgoing/DocMsgOutgoing";
import VideoMsgOutgoing from "./outgoing/VideoMsgOutgoing";
import ImageMsgOutgoing from "./outgoing/ImageMsgOutgoing";
import ButtonMsgOutgoing from "./outgoing/ButtonMsgOutgoing";
import ListMsgOutgoing from "./outgoing/ListMsgOutgoing";
import InteractiveMsgOutgoing from "./outgoing/InteractiveMsgOutgoing";

const OneChat = ({ i, data, chatConvo, key }) => {
  return (
    <div>
      {i?.route === "INCOMING" && (
        <Box>
          {i?.type === "text" && (
            <TextMsg chatConvo={chatConvo} i={i} data={data} />
          )}
          {i?.type === "image" && (
            <ImageMsg chatConvo={chatConvo} i={i} data={data} />
          )}
          {i?.type === "audio" && (
            <AudioMsg chatConvo={chatConvo} i={i} data={data} />
          )}
          {i?.type === "document" && (
            <DocMsg chatConvo={chatConvo} i={i} data={data} />
          )}
          {i?.type === "video" && (
            <VideoMsg chatConvo={chatConvo} i={i} data={data} />
          )}
        </Box>
      )}

      {i?.route === "OUTGOING" && (
        <Box>
          {i?.type === "text" && (
            <TextMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
          {i?.type === "image" && (
            <ImageMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
          {i?.type === "audio" && (
            <AudioMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
          {i?.type === "document" && (
            <DocMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
          {i?.type === "video" && (
            <VideoMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
          {i?.type === "button" && (
            <ButtonMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
          {i?.type === "list" && (
            <ListMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
          {i?.type === "interactive" && (
            <InteractiveMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
            />
          )}
        </Box>
      )}
    </div>
  );
};

export default OneChat;
