import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardActionArea, CircularProgress, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import CloseBtn from '../../../../context/CloseBtn'
import { GlobalContext } from '../../../../context/GlobalContext'
import { ExpandMore, Send, } from '@mui/icons-material'
import TextFieldNew from '../../../../components/TextFieldNew'
import { LoadingButton } from '@mui/lab'

const SendBottomTemplet = ({ state, setState, INBOX, data }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [templets, setTemplets] = React.useState([])
    const CON = React.useContext(GlobalContext)
    const [example, setExample] = React.useState([])

    async function getTemplets() {
        const res = await hitAxios({
            path: "/api/user/get_my_meta_templets",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            const newArrMan = res.data?.data?.length > 0 ? res.data?.data : []
            const findApproved = newArrMan?.filter((i) => i.status === "APPROVED")
            setTemplets(findApproved)
        }
    }

    function extractVariables(text) {
        // Regular expression to match '{{textsome}}' pattern
        const regex = /\{\{([^{}]+)\}\}/g;
        const matches = [];

        // Using a loop to find all matches
        let match;
        while ((match = regex.exec(text)) !== null) {
            matches.push(match[1]); // Capture group 1 contains the text inside {{ }}
        }

        return matches;
    }

    async function sendTempletMeta(template) {
        const res = await hitAxios({
            path: "/api/inbox/send_meta_templet",
            post: true,
            admin: false,
            obj: {
                toNumber: INBOX?.data?.openedChat?.sender_mobile,
                toName: INBOX?.data?.openedChat?.sender_name,
                chatId: INBOX?.data?.openedChat?.chat_id,
                template,
                example
            }
        })
        console.log(res.data)
        if (res.data.success) {
            setState({ ...state, dialogTemplet: false })
        }

    }


    React.useEffect(() => {
        getTemplets()
    }, [])

    return (
        <Dialog
            fullWidth
            PaperProps={{
                style: { borderRadius: 10 }
            }}
            key="7"
            open={state.dialogTemplet}
            onClose={() => setState({ ...state, dialogTemplet: false })}
        >
            <Box p={2} >
                <Stack alignItems={'center'} mb={2} direction={'row'} justifyContent={'space-between'}>
                    <Typography fontWeight={600} variant='h6' >{data.sendUsingTemplets}</Typography>
                    <CloseBtn
                        onClick={() => setState({ ...state, dialogTemplet: false })}
                    />
                </Stack>

                <Divider />

                <Box mt={2}>
                    <Stack direction={'column'} spacing={2} >
                        {templets?.map((i, key) => {
                            return (
                                <Accordion
                                    key={key}
                                    disableGutters
                                    elevation={0}
                                    sx={{
                                        bgcolor: "action.hover", borderRadius: 3,
                                        '&:before': {
                                            display: 'none',
                                        }
                                    }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}>
                                        <Typography fontWeight={600}>{i?.name}</Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Stack direction={'column'} spacing={2}>

                                            <Box bgcolor={'white'} borderRadius={2} p={2}>
                                                <Typography variant='body2'>
                                                    {i?.components?.filter(ii => ii.type === "BODY")[0]?.text}
                                                </Typography>
                                            </Box>

                                            {extractVariables(i?.components?.filter(ii => ii.type === "BODY")[0]?.text)?.map((item, key) => {
                                                return (
                                                    <TextFieldNew
                                                        fullWidth
                                                        key={key}
                                                        label={`${data?.contentFor} {{${item}}}`}
                                                        value={example[key] || ''}
                                                        onChange={(e) => {
                                                            const updatedExample = [...example];
                                                            updatedExample[key] = e.target.value.slice(0, 200)
                                                            setExample(updatedExample);
                                                        }}
                                                        helperText={`${data.sampleContentHelper} - ${example[key]?.length || 0}/${data?.allowed200}`}
                                                    />
                                                )
                                            })}
                                            <Stack>
                                                <LoadingButton
                                                    onClick={() =>
                                                        sendTempletMeta(i, extractVariables(i?.components?.filter(ii => ii.type === "BODY")[0]?.text))}
                                                    loading={CON.data?.loading}
                                                    startIcon={<Send />}
                                                    size='small'
                                                    sx={{ boxShadow: 0, borderRadius: 2 }}
                                                    variant='contained' >
                                                    send</LoadingButton>
                                            </Stack>
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    )
}

export default SendBottomTemplet