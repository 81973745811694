import React from 'react'

export const InboxContext = React.createContext(null)

export const InboxProvider = (props) => {
    const [data, setData] = React.useState({
        openedChat: {}
    })

    return (
        <InboxContext.Provider value={{ data, setData }}>
            {props.children}
        </InboxContext.Provider>
    )
}

