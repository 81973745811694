import React from 'react'
import { Box, Typography, Button, Stack, IconButton, Dialog, AppBar, Toolbar, Container, Divider, TextField, CircularProgress } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { Add, Close, Delete, Edit, Save } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'

const Translation = () => {
    const { data } = React.useContext(TranslateContext)
    const G = React.useContext(GlobalContext)
    const [lang, setLang] = React.useState([])
    const [state, setState] = React.useState({
        dialog: false,
        dialogAddNew: false
    })
    const [editLang, setEditLang] = React.useState([])


    async function delOne(e) {
        if (window.confirm(data.AUS)) {
            const res = await G.hitAxios({
                path: "/api/web/del-one-translation",
                admin: true,
                post: true,
                obj: { code: e }
            })
            if (res.data.success) {
                getLangs()
            }
        }
    }


    async function getLangs() {
        const res = await G.hitAxios({
            path: "/api/web/get-all-translation-name",
            admin: false,
            post: false
        })
        if (res.data.success) {
            setLang(res.data.data)
        }
    }

    async function addNewLang() {
        const res = await G.hitAxios({
            path: "/api/web/add-new-translation",
            post: true,
            admin: true,
            obj: { newcode: state.newcode }
        })
        if (res.data.success) {
            setState({ ...state, dialogAddNew: false })
            getLangs()
        }
    }

    async function updateLang() {
        const originalObject = {};

        editLang.forEach((item) => {
            originalObject[item.var] = item.name;
        });

        const res = await G.hitAxios({
            path: "/api/web/update-one-translation",
            post: true,
            admin: true,
            obj: { code: state.langCode, updatedjson: originalObject }
        })
    }

    function parseJsonObject(jsonObject) {
        // Initialize an empty array to store the result
        const resultArray = [];

        // Iterate over the keys in the JSON object
        for (const key in jsonObject) {
            // Create an object with 'var' and 'name' properties
            const obj = {
                var: key,
                name: jsonObject[key]
            };

            // Push the object into the result array
            resultArray.push(obj);
        }

        return resultArray;
    }

    async function getOneLang(code, i) {
        const res = await G.hitAxios({
            path: `/api/web/get-one-translation?code=${code}`,
            admin: false,
            post: false
        })

        console.log(res.data)
        if (res.data.success) {
            const result = parseJsonObject(res.data?.data)
            setEditLang(result)
            setState({ ...state, dialog: true, langCode: i?.replace(".json", "") })
        }
    }

    React.useEffect(() => {
        getLangs()
    }, [])

    return (
        <Box mt={4}>

            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'}>
                <Stack direction={'column'}>
                    <Typography
                        fontWeight={'bold'}
                        variant='h6'>{data.webTranslation}</Typography>
                    <Typography
                        color={'gray'}
                        variant='caption'>{data.webTransDes}</Typography>
                </Stack>

                <Button
                    onClick={() => setState({ ...state, dialogAddNew: true })}
                    sx={{ borderRadius: 2, boxShadow: 0 }}
                    variant='contained' >
                    {data.addNewLanguage}
                </Button>
            </Stack>


            <Dialog
                fullWidth
                onClose={(e) => setState({ ...state, dialogAddNew: false })}
                open={state.dialogAddNew}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setState({ ...state, dialogAddNew: false })} >
                            <Close />
                        </IconButton>
                        <Typography>{data.addNew}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={'column'} spacing={2} mt={2}>
                        <TextField
                            helperText={data.addLangFiled}
                            value={state.newcode}
                            onChange={(e) => setState({ ...state, newcode: e.target.value?.replace(/\s/g, "") })}
                            label={data.enterNewLangugename} size='small'
                            InputProps={{ style: { borderRadius: 10 } }} />

                        <Button
                            onClick={addNewLang}
                            disabled={state.newcode ? false : true}
                            variant='contained'
                            sx={{ borderRadius: 2, textTransform: 'none' }}
                        >{data.submit}</Button>
                    </Stack>
                </Box>
            </Dialog>

            <Dialog fullScreen open={state.dialog}>
                <AppBar position='static'>
                    <Toolbar>
                        <Stack spacing={2} direction={'row'} >
                            <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                <IconButton onClick={() => setState({ ...state, dialog: false })} >
                                    <Close />
                                </IconButton>
                                <Typography>{data.edit} - {state?.langCode}</Typography>
                            </Stack>

                            <IconButton onClick={updateLang} >
                                <Save sx={{ color: 'white' }} />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Box mt={2} mb={4}>
                    <Container maxWidth='lg'>
                        <Stack direction='column' spacing={2}>
                            {editLang?.map((i, key) => {
                                return (
                                    <TextField
                                        onChange={(e) => setEditLang((prevState) => {
                                            const updatedLang = [...prevState];
                                            updatedLang[key] = { ...updatedLang[key], name: e.target.value };
                                            return updatedLang;
                                        })}
                                        helperText={i.var == 'color_theme' && data.addHexOnly || i.var == 'color_front' && data.addHexOnly}
                                        value={i.name}
                                        InputProps={{ style: { borderRadius: 10 } }}
                                        label={i.var}
                                        fullWidth
                                        size='small'
                                    />
                                )
                            })}
                            <Button
                                onClick={updateLang}
                                variant='contained'
                                sx={{ borderRadius: 2, textTransform: 'none' }}
                            >{data.submit}</Button>
                        </Stack>
                    </Container>
                </Box>
            </Dialog>

            <Box mt={4}>
                {lang &&
                    <Stack direction={'column'} spacing={2}>
                        {lang.map((i, key) => {
                            return (
                                <Box key={key} borderRadius={4} p={3} sx={{ bgcolor: 'action.hover' }}>
                                    <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} spacing={2}>
                                        <Stack direction={'column'}>
                                            <Typography fontWeight={'bold'} >{i?.replace(".json", "")}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} spacing={2}>
                                            {G.data.loading ?
                                                <CircularProgress size={18} /> :
                                                <IconButton onClick={() => {
                                                    getOneLang(i?.replace(".json", ""), i)

                                                }} >
                                                    <Edit />
                                                </IconButton>}
                                            <IconButton onClick={() => delOne(i?.replace(".json", ""))} >
                                                <Delete color='error' />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Stack>}
            </Box>

        </Box>
    )
}

export default Translation
