import { Translate } from '@mui/icons-material'
import { Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import DashboardHeader from './DashboardHeader'
import { TranslateContext } from '../context/TranslateContext'
import PhoneBook from './phonebook/PhoneBook'
import ChatFlow from './chat-flow/ChatFlow'
import Inbox from './inbox/Inbox'
import { getUser } from '../utils/GetMe'
import { GlobalContext } from '../context/GlobalContext'
import Chatbot from './chatbot/Chatbot'
import BroadCast from './broadcast/BroadCast'
import Dashboard from './dashboard/Dashboard'
import ApiAccess from './apiAccess/ApiAccess'

const UserDashboard = () => {
    const { data } = React.useContext(TranslateContext)
    const [page, setPage] = React.useState(null)
    const CON = React.useContext(GlobalContext)

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('page');

    async function updateUser() {
        const user = await getUser()
        CON.setData({ ...CON.data, userData: user })
    }

    React.useEffect(() => {
        updateUser()
    }, [])

    React.useEffect(() => {
        setPage(foo || "dashboard")
    }, [foo])

    return (
        <div>
            {CON.data?.userData ?
                <Box
                    minHeight={'100vh'} bgcolor={'#f1f1f1'} >

                    <DashboardHeader page={page} setPage={setPage} data={data} />
                    {page === "" && < Dashboard data={data} />}
                    {page === "dashboard" && < Dashboard data={data} />}
                    {page === "phonebook" && <PhoneBook data={data} />}
                    {page === "chatbot-flow" && <ChatFlow data={data} />}
                    {page === "inbox" && <Inbox data={data} />}
                    {page === "chatbot" && <Chatbot data={data} />}
                    {page === "broadcast" && <BroadCast data={data} />}
                    {page === "api-access" && <ApiAccess data={data} />}
                </Box> : <LinearProgress />}
        </div>
    )
}

export default UserDashboard