import { AlignHorizontalLeft, Check, Close, DoneAll, HourglassTopOutlined, SentimentNeutralOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Container, Dialog, Divider, Grid, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import moment from 'moment'

const ViewBroadcast = ({ data, dialog, setDialog, logs, CON, hitAxios, getBroadList, logStatus }) => {

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box width={'100%'}  >
                    <Grid container alignItems={'center'} justifyContent={'flex-end'} spacing={2}>
                        <Grid item>
                            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                <Typography variant='h5' >{data.contactList}</Typography>
                                <GridToolbarExport />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </GridToolbarContainer >
        );
    }

    async function changeBStatus(status, broadcast_id) {
        const res = await hitAxios({
            path: "/api/broadcast/change_broadcast_status",
            post: true,
            admin: false,
            obj: { status, broadcast_id }
        })
        if (res.data.success) {
            getBroadList()
            setDialog({ ...dialog, openData: { ...dialog.openData, status } })
        }
    }

    return (
        <Dialog
            fullScreen
            open={dialog.open}
            onClose={() => setDialog({ ...dialog, open: true })}
        >
            <Box sx={{ bgcolor: (t) => t.palette.primary.main }} p={2} >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1} >
                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                        <IconButton onClick={() => setDialog({ ...dialog, open: false })} >
                            <Close sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography color={'white'} variant='h5' >{dialog?.openData?.title}</Typography>
                    </Stack>

                    <Tooltip title={data.changeCampaignStatus} >
                        {CON.data?.loading ? <CircularProgress color='inherit' size={26} /> :
                            <Switch
                                onChange={(e) => {
                                    changeBStatus(e.target.checked ? "QUEUE" : "PAUSED", dialog?.openData?.broadcast_id)
                                }}
                                checked={dialog?.openData?.status === "QUEUE" ? true : false}
                                sx={{ bgcolor: '#F0F0F0', borderRadius: 4 }} />}
                    </Tooltip>
                </Stack>
            </Box>

            <Box mt={2} >
                <Container maxWidth='lg'>

                    <Box mt={2}>

                        <Grid container spacing={2}>
                            <Grid xs={12} sm={4} lg={3} item>
                                <Box p={2} borderRadius={4} bgcolor={'#F3F3F3'} >
                                    <Stack direction={'column'} spacing={2}>
                                        <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                            <Typography variant='h4' >{logStatus?.totalLogs}</Typography>
                                            <AlignHorizontalLeft sx={{ color: (t) => t.palette.primary.main, height: 30, width: 30, bgcolor: 'white', borderRadius: "50%", p: 1 }} />
                                        </Stack>

                                        <Typography color={'#6B6B6B'} fontWeight={600} >{data.totalMessages}</Typography>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid xs={12} sm={4} lg={3} item>
                                <Box p={2} borderRadius={4} bgcolor={'#F3F3F3'} >
                                    <Stack direction={'column'} spacing={2}>
                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'} >
                                            <Typography variant='h4' >{logStatus?.getSent}</Typography>
                                            <Check sx={{ color: (t) => t.palette.primary.main, height: 30, width: 30, bgcolor: 'white', borderRadius: "50%", p: 1 }} />
                                        </Stack>

                                        <Typography color={'#6B6B6B'} fontWeight={600} >{data.sent}</Typography>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid xs={12} sm={4} lg={3} item>
                                <Box p={2} borderRadius={4} bgcolor={'#F3F3F3'} >
                                    <Stack direction={'column'} spacing={2}>
                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'} >
                                            <Typography variant='h4' >{logStatus?.totalRead}</Typography>
                                            <DoneAll sx={{ color: (t) => t.palette.primary.main, height: 30, width: 30, bgcolor: 'white', borderRadius: "50%", p: 1 }} />
                                        </Stack>

                                        <Typography color={'#6B6B6B'} fontWeight={600} >{data.read}</Typography>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid xs={12} sm={4} lg={3} item>
                                <Box p={2} borderRadius={4} bgcolor={'#F3F3F3'} >
                                    <Stack direction={'column'} spacing={2}>
                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'} >
                                            <Typography variant='h4' >{logStatus?.totalPending}</Typography>
                                            <HourglassTopOutlined sx={{ color: (t) => t.palette.primary.main, height: 30, width: 30, bgcolor: 'white', borderRadius: "50%", p: 1 }} />
                                        </Stack>

                                        <Typography color={'#6B6B6B'} fontWeight={600} >{data.pending}</Typography>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid xs={12} sm={4} lg={3} item>
                                <Box p={2} borderRadius={4} bgcolor={'#F3F3F3'} >
                                    <Stack direction={'column'} spacing={2}>
                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'} >
                                            <Typography variant='h4' >{logStatus?.totalFailed}</Typography>
                                            <SentimentNeutralOutlined sx={{ color: (t) => t.palette.primary.main, height: 30, width: 30, bgcolor: 'white', borderRadius: "50%", p: 1 }} />
                                        </Stack>

                                        <Typography color={'#6B6B6B'} fontWeight={600} >{data.failed}</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box p={2}>
                        <Divider />
                    </Box>

                    <Box bgcolor={'action.hover'} borderRadius={4} >
                        {logs ?
                            <Box height={'76vh'}>
                                <DataGrid
                                    getRowId={(row) => row.id}
                                    rows={[...logs].reverse()}
                                    columns={[
                                        {
                                            headerName: data.templetName, field: 'templet_name', flex: 1, renderCell: dataa => {
                                                return (
                                                    <Tooltip title={dataa.row?.templet_name} >
                                                        {dataa.row?.templet_name}
                                                    </Tooltip>
                                                )
                                            }
                                        },
                                        {
                                            headerName: data.sender, field: 'sender_mobile', flex: 1, renderCell: dataa => {
                                                return (
                                                    <Tooltip title={dataa.row?.sender_mobile} >
                                                        {dataa.row?.sender_mobile}
                                                    </Tooltip>
                                                )
                                            }
                                        },
                                        {
                                            headerName: data.sendTo, field: 'send_to', flex: 1, renderCell: dataa => {
                                                return (
                                                    <Tooltip title={dataa.row?.send_to} >
                                                        +{dataa.row?.send_to}
                                                    </Tooltip>
                                                )
                                            }
                                        },
                                        {
                                            headerName: data.status, field: 'delivery_status', flex: 1, renderCell: dataa => {
                                                return (
                                                    <Tooltip title={dataa.row?.delivery_status} >
                                                        {dataa.row?.delivery_status}
                                                    </Tooltip>
                                                )
                                            }
                                        },
                                        {
                                            headerName: data.sendingTIme, field: 'delivery_time', flex: 1, renderCell: dataa => {
                                                return (
                                                    <Tooltip title={dataa.row?.delivery_time ? moment(dataa.row?.delivery_time).format("DD-MM-YY | hh:mmA") : data.na} >
                                                        {dataa.row?.delivery_time ? moment(dataa.row?.delivery_time).format("DD-MM-YY | hh:mmA") : data.na}
                                                    </Tooltip>
                                                )
                                            }
                                        }
                                    ]}
                                    pageSize={50}
                                    rowsPerPageOptions={[50]}
                                    checkboxSelection={false}
                                    components={{
                                        Toolbar: CustomToolbar
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        borderRadius: 4,
                                        border: 0,
                                        fontSize: 13,
                                        padding: 2
                                    }}
                                />
                            </Box> : ""
                        }
                    </Box>
                </Container>
            </Box>
        </Dialog>
    )
}

export default ViewBroadcast