import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HomeMainPage from "./HomeMainPage";
import { GlobalProvider } from "./context/GlobalContext";
import { TranslateProvider } from "./context/TranslateContext";
import GetTrans from "./utils/GetTrans";
import { FlowProvider } from "./context/FlowContext";
import { InboxProvider } from "./context/InboxContext";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function ToggleColorMode() {
  const [splash, setSplash] = React.useState(true);
  const { trans } = GetTrans();

  // React.useEffect(() => {
  //   setMode(localStorage.getItem('theme') ? (localStorage.getItem('theme')) : (localStorage.setItem('theme', 'dark'), "dark"))
  //   setTimeout(() => {
  //     setSplash(false)

  //   }, 1000);
  // }, [])

  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "light" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ["Outfit"].join(","),
        },
        palette: {
          mode,
          primary: {
            main: trans ? trans.color_theme : "#22a454",
          },
        },
      }),
    [mode, trans]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <GlobalProvider>
        <TranslateProvider>
          <FlowProvider>
            <InboxProvider>
              <ThemeProvider theme={theme}>
                <HomeMainPage />
              </ThemeProvider>
            </InboxProvider>
          </FlowProvider>
        </TranslateProvider>
      </GlobalProvider>
    </ColorModeContext.Provider>
  );
}
