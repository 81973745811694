import React from 'react'
import { TranslateContext } from '../../context/TranslateContext';
import Header from '../components/Header';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { ConnectWithoutContact, Send } from '@mui/icons-material';
import TextFieldNew from '../../components/TextFieldNew';
import ButtonComp from '../../context/ButtonComp';
import { GlobalContext } from '../../context/GlobalContext';
import Footer from '../components/Footer';

const ContactForm = () => {
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [state, setState] = React.useState({

    })

    async function addContactForm() {
        const res = await hitAxios({
            path: "/api/web/submit_contact_form",
            post: true,
            admin: false,
            obj: state
        })
        if (res.data.success) {
            setState({ ...state, name: "", email: "", content: "", mobile: "" })
        }
    }

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    return (
        <div>
            <Header
                screenWidth={screenWidth}
                data={data}
            />

            <Container
                maxWidth='lg'>
                <Box mt={4}>
                    <Stack direction={'column'} spacing={4}>

                        <Stack alignItems={'flex-start'} direction={'row'} spacing={2}>

                            <Box pt={1} >
                                <ConnectWithoutContact sx={{ color: "#16A9C4", height: 40, width: 40 }} />
                            </Box>


                            <Stack direction={'column'}>
                                <Typography variant='h3' fontWeight={600} >{data.contactUs}</Typography>
                                <Typography fontWeight={500} >{data.filltheForm}</Typography>
                            </Stack>

                        </Stack>


                        <Box
                            sx={{ borderRight: 5, borderColor: (t) => t.palette.primary.main, borderTop: 5, borderTopColor: (t) => t.palette.primary.main }}
                            bgcolor={'action.hover'} p={screenWidth > 899 ? 5 : 3} borderRadius={3}>
                            <Stack direction={'column'} spacing={2}>
                                <TextFieldNew
                                    value={state.name}
                                    onChange={(e) => setState({ ...state, name: e.target.value })}
                                    label={data.name}
                                />
                                <TextFieldNew
                                    value={state.mobile}
                                    onChange={(e) => setState({ ...state, mobile: e.target.value })}
                                    label={data.mobile}
                                />
                                <TextFieldNew
                                    value={state.email}
                                    onChange={(e) => setState({ ...state, email: e.target.value })}
                                    label={data.yourEmail}
                                />
                                <TextFieldNew
                                    value={state.content}
                                    onChange={(e) => setState({ ...state, content: e.target.value })}
                                    multiline
                                    rows={4}
                                    label={data.yourMsg}
                                />

                                <Stack pt={2}>
                                    <ButtonComp
                                        onClick={addContactForm}
                                        startIcon={<Send />}
                                        title={data.submit}
                                    />
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>

                    <Box pb={4} pt={4}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={4} lg={4} item>
                                <Box minHeight={120} bgcolor={'#fdedfe'} borderRadius={3} p={3}>
                                    <Stack direction={'column'} spacing={2}>
                                        <Typography variant='h6' fontWeight={600} >1-on-1 or group demos</Typography>
                                        <Typography fontWeight={350} >Whether your demo is for an individual or a team spread over different locations, see how Wati works with WhatsApp.</Typography>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid xs={12} sm={4} lg={4} item>
                                <Box minHeight={120} bgcolor={'#e2f5fe'} borderRadius={3} p={3}>
                                    <Stack direction={'column'} spacing={2}>
                                        <Typography variant='h6' fontWeight={600} >1-on-1 or group demos</Typography>
                                        <Typography fontWeight={350} >Whether your demo is for an individual or a team spread over different locations, see how Wati works with WhatsApp.</Typography>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid xs={12} sm={4} lg={4} item>
                                <Box minHeight={120} bgcolor={'#fff7df'} borderRadius={3} p={3}>
                                    <Stack direction={'column'} spacing={2}>
                                        <Typography variant='h6' fontWeight={600} >1-on-1 or group demos</Typography>
                                        <Typography fontWeight={350} >Whether your demo is for an individual or a team spread over different locations, see how Wati works with WhatsApp.</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>

            <Footer
                screenWidth={screenWidth}
                data={data}
            />
        </div>
    )
}

export default ContactForm