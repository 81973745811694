import { Box, CardMedia, Container, ImageList, Stack, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Page = ({ data, screenWidth }) => {
    const [page, setPage] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)
    const history = useHistory()

    async function getPages() {
        const res = await hitAxios({
            path: "/api/admin/get_pages",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setPage(res.data.data)
        }
    }

    React.useEffect(() => {
        getPages()
    }, [])

    return (
        <div>
            <Box pb={screenWidth > 499 ? 6 : 3} pt={screenWidth > 499 ? 6 : 3}  >

                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={5}>
                        <Stack spacing={1} alignItems={'center'}>
                            <Typography align='center' fontWeight={600} variant={screenWidth > 699 ? 'h4' : 'h5'} >
                                {data.advantageOf} {data.appName}
                            </Typography>
                        </Stack>

                        <Stack direction={'column'} spacing={3}>
                            <ImageList
                                sx={{
                                    gridAutoFlow: "column",
                                    gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                                    gridAutoColumns: "minmax(160px, 1fr)",
                                    msOverflowStyle: 'none',
                                    overflowX: 'scroll',
                                    '::-webkit-scrollbar': {
                                        width: '10px'
                                    }
                                }}
                            >
                                <Stack spacing={4} direction={'row'}>
                                    {page?.map((i, key) => {
                                        return (
                                            <Box
                                                onClick={() => history.push(`/view/${i?.slug}`)}
                                                sx={{ cursor: 'pointer' }}
                                                p={1} borderRadius={4} bgcolor={'#F5F5F5'} minWidth={300} key={key} >
                                                <Stack direction={'column'} spacing={1}>
                                                    <CardMedia
                                                        sx={{ height: 200, width: "100%", borderRadius: 4 }}
                                                        src={`/media/${i?.image}`}
                                                        component={'img'}
                                                    />
                                                    <Stack justifyContent={'space-between'} direction={'column'}>
                                                        <Stack direction={'column'}>
                                                            <Typography fontWeight={500} variant='h6' >
                                                                {i?.title}
                                                            </Typography>

                                                            <Typography
                                                                sx={{ display: "inline-block" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: i.content?.length > 104 ? i.content?.slice(0, 104) + "..." : i.content
                                                                }}
                                                                fontWeight={400} variant='body2' />
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        )
                                    })}
                                </Stack>
                            </ImageList>
                        </Stack>

                    </Stack>
                </Container>
            </Box>
        </div>
    )
}

export default Page