import { KeyOutlined } from '@mui/icons-material'
import { Box, Button, CardActionArea, CardMedia, Stack, Typography } from '@mui/material'
import React from 'react'
import ConversationalApi from './ConversationalApi'

const LeftSide = ({ setMenu, menu, state, setState, data, screenWidth, hitAxios }) => {
    const [profile, setProfile] = React.useState({

    })

    async function genAPI() {
        const res = await hitAxios({
            path: "/api/user/generate_api_keys",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            getProfile()
        }
    }

    async function getProfile() {
        const res = await hitAxios({
            path: "/api/user/get_me",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setProfile({ ...res.data.data })
        }
    }

    React.useEffect(() => {
        getProfile()
    }, [])

    return (
        <div>
            <Box borderRadius={2} bgcolor={'white'} m={2} p={2}>
                <Stack alignItems={'center'} direction={'column'} spacing={1}>

                    <CardMedia
                        sx={{ height: "100%", width: 100 }}
                        component={'img'}
                        src='/assets/api_key.svg'
                    />

                    {profile?.api_key &&
                        <Box sx={{ overflowWrap: 'break-word' }}>
                            <CardActionArea onClick={() => {
                                navigator.clipboard.writeText(profile.api_key).then(() => {
                                    alert(data.apiCopied)
                                })
                            }} sx={{ borderRadius: 2, p: 2 }} >
                                <Typography
                                    variant='body2'
                                    align='center'
                                    fontStyle={'italic'} sx={{ wordBreak: 'break-all' }}>{profile.api_key}</Typography>
                            </CardActionArea>
                        </Box>
                    }
                    <Button
                        startIcon={<KeyOutlined />}
                        onClick={genAPI}
                    >
                        {data.genAPIKeys}
                    </Button>
                </Stack>
            </Box>

            <Box mt={2} borderRadius={2} bgcolor={'white'} m={2} p={2}>
                <ConversationalApi
                    hitAxios={hitAxios}
                    data={data}
                />
            </Box>
        </div>
    )
}

export default LeftSide