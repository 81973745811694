import { ArrowForwardIos, CheckCircleRounded, TaskAlt } from '@mui/icons-material'
import { Box, Button, CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import ButtonComp from '../../context/ButtonComp'

const Features = ({ data, screenWidth }) => {
    return (
        <Box mb={6} mt={6}>
            <Container maxWidth='lg'>
                <Stack direction={'column'} alignItems={'center'}>
                    <Box bgcolor={'#e2f5fe'} sx={{ p: "15px 25px 15px 25px" }} borderRadius={4}>
                        <Typography fontWeight={600} variant='h5' >{data.features}</Typography>
                    </Box>
                </Stack>

                <Box
                    mt={4}
                    sx={{ borderRight: 5, borderRightColor: '#51c3fe', borderBottom: 5, borderBottomColor: '#51c3fe' }}
                    bgcolor={'#e2f5fe'} p={screenWidth > 899 ? 6 : 3} borderRadius={4}>

                    <Grid justifyContent={'space-between'} alignItems={'center'} container spacing={4} >
                        <Grid xs={12} md={6} sm={12} lg={5} item>
                            <CardMedia
                                component={'img'}
                                src='/assets/broadcast_feature.png'
                            />
                        </Grid>

                        <Grid xs={12} md={6} sm={12} lg={6} item>
                            <Stack direction={'column'} spacing={2}>
                                <Typography variant='h5' fontWeight={600} >{data.broadCast}</Typography>

                                <Stack direction={'row'} spacing={0.5}>
                                    <Box pt={0.5} >
                                        <TaskAlt sx={{ height: 18, width: 18 }} />
                                    </Box>
                                    <Typography fontWeight={450} >{data.broFeature1}</Typography>
                                </Stack>

                                <Stack direction={'row'} spacing={0.5}>
                                    <Box pt={0.5} >
                                        <TaskAlt sx={{ height: 18, width: 18 }} />
                                    </Box>
                                    <Typography fontWeight={450} >{data.broFea2}</Typography>
                                </Stack>

                                <Stack direction={'row'} spacing={0.5}>
                                    <Box pt={0.5} >
                                        <TaskAlt sx={{ height: 18, width: 18 }} />
                                    </Box>
                                    <Typography fontWeight={450} >{data.broFea3}</Typography>
                                </Stack>

                                <Stack pt={2} direction={'row'}>
                                    <ButtonComp
                                        bgcolor={data.color_front}
                                        borderWeight={2}
                                        fontWeight={600}
                                        color={'black'}
                                        title={data.getStarted}
                                        startIcon={<ArrowForwardIos sx={{ height: 20, width: 20 }} />}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>


                    <Box mt={8} >
                        <Grid justifyContent={'space-between'} alignItems={'center'} container spacing={4} >

                            {screenWidth < 900 &&
                                <Grid xs={12} md={6} sm={12} lg={5} item>
                                    <CardMedia
                                        component={'img'}
                                        src='/assets/chatbot_feature.png'
                                    />
                                </Grid>}

                            <Grid xs={12} md={6} sm={12} lg={6} item>
                                <Stack direction={'column'} spacing={2}>
                                    <Typography variant='h5' fontWeight={600} >{data.chatbots}</Typography>

                                    <Stack direction={'row'} spacing={0.5}>
                                        <Box pt={0.5} >
                                            <TaskAlt sx={{ height: 18, width: 18 }} />
                                        </Box>
                                        <Typography fontWeight={450} >{data.chatFea1}</Typography>
                                    </Stack>

                                    <Stack direction={'row'} spacing={0.5}>
                                        <Box pt={0.5} >
                                            <TaskAlt sx={{ height: 18, width: 18 }} />
                                        </Box>
                                        <Typography fontWeight={450} >{data.chatFea2}</Typography>
                                    </Stack>

                                    <Stack direction={'row'} spacing={0.5}>
                                        <Box pt={0.5} >
                                            <TaskAlt sx={{ height: 18, width: 18 }} />
                                        </Box>
                                        <Typography fontWeight={450} >{data.chatFEa3}</Typography>
                                    </Stack>

                                    <Stack pt={2} direction={'row'}>
                                        <ButtonComp
                                            bgcolor={data.color_front}
                                            borderWeight={2}
                                            fontWeight={600}
                                            color={'black'}
                                            title={data.getStarted}
                                            startIcon={<ArrowForwardIos sx={{ height: 20, width: 20 }} />}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>

                            {screenWidth > 899 &&
                                <Grid xs={12} md={6} sm={12} lg={5} item>
                                    <CardMedia
                                        component={'img'}
                                        src='/assets/chatbot_feature.png'
                                    />
                                </Grid>}
                        </Grid>
                    </Box>

                    <Box mt={8}>
                        <Grid justifyContent={'space-between'} alignItems={'center'} container spacing={4} >
                            <Grid xs={12} md={6} sm={12} lg={5} item>
                                <CardMedia
                                    component={'img'}
                                    src='/assets/chat_feature.png'
                                />
                            </Grid>

                            <Grid xs={12} md={6} sm={12} lg={6} item>
                                <Stack direction={'column'} spacing={2}>
                                    <Typography variant='h5' fontWeight={600} >{data.onlineCRm}</Typography>

                                    <Stack direction={'row'} spacing={0.5}>
                                        <Box pt={0.5} >
                                            <TaskAlt sx={{ height: 18, width: 18 }} />
                                        </Box>
                                        <Typography fontWeight={450} >{data.onlCrm1}</Typography>
                                    </Stack>

                                    <Stack direction={'row'} spacing={0.5}>
                                        <Box pt={0.5} >
                                            <TaskAlt sx={{ height: 18, width: 18 }} />
                                        </Box>
                                        <Typography fontWeight={450} >{data.onCrm2}</Typography>
                                    </Stack>

                                    <Stack direction={'row'} spacing={0.5}>
                                        <Box pt={0.5} >
                                            <TaskAlt sx={{ height: 18, width: 18 }} />
                                        </Box>
                                        <Typography fontWeight={450} >{data.onCrm3}</Typography>
                                    </Stack>

                                    <Stack pt={2} direction={'row'}>
                                        <ButtonComp
                                            bgcolor={data.color_front}
                                            borderWeight={2}
                                            fontWeight={600}
                                            color={'black'}
                                            title={data.getStarted}
                                            startIcon={<ArrowForwardIos sx={{ height: 20, width: 20 }} />}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>


                <Container maxWidth='sm'>
                    <Stack mt={4} >
                        <Stack alignItems={'center'} mt={2} direction={'row'} spacing={2}>
                            <ButtonComp
                                bgcolor={data.color_front}
                                borderWeight={2}
                                fontWeight={600}
                                color={'black'}
                                title={data.tryNow}
                                fullWidth
                            />
                            <Button
                                color='inherit'
                                endIcon={<ArrowForwardIos />}
                                sx={{ textTransform: 'none' }}
                                fullWidth>
                                {data.orBookDemo}</Button>
                        </Stack>
                    </Stack>
                </Container>
            </Container >
        </Box >
    )
}

export default Features