import React from 'react'
import PlanComponent from '../../components/PlanComponent'
import { Box, Container, Typography, Stack } from '@mui/material'

const Plans = ({ data, screenWidth }) => {
    return (
        <div>
            <Box
                pb={screenWidth > 499 ? 6 : 3} pt={screenWidth > 499 ? 6 : 3} bgcolor={'white'} >
                <Container maxWidth='lg'>
                    <Stack mb={4} direction={'column'} alignItems={'center'}>
                        <Box bgcolor={'#d4fde8'} sx={{ p: "15px 25px 15px 25px" }} borderRadius={4}>
                            <Typography color={'#1C5F1B'} fontWeight={600} variant='h5' >{data.price}</Typography>
                        </Box>
                    </Stack>

                    <PlanComponent
                    // bgColor={'#d4fde8'}
                    />
                </Container>
            </Box>
        </div>
    )
}

export default Plans