import { Box, Container, Grid, ImageList, Stack, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'

const Testimonial = ({ data, screenWidth }) => {
    const [testi, setTesti] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)
    async function getTesti() {
        const res = await hitAxios({
            path: "/api/admin/get_testi",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setTesti(res.data.data)
        }
    }

    React.useEffect(() => {
        getTesti()
    }, [])


    return (
        <div>
            <Box pb={screenWidth > 499 ? 6 : 3} pt={screenWidth > 499 ? 6 : 3} bgcolor={'#ffe96f'} >
                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={4}>
                        <Stack direction={'column'} spacing={2}>
                            <Typography align='center' fontWeight={600} variant='h4'>
                                {data.testimonialTitle}
                            </Typography>
                            <Typography align='center'>
                                {data.testimonialDes}
                            </Typography>
                        </Stack>

                        <ImageList
                            sx={{
                                gridAutoFlow: "column",
                                gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                                gridAutoColumns: "minmax(160px, 1fr)",
                                msOverflowStyle: 'none',
                                overflowX: 'scroll',
                                '::-webkit-scrollbar': {
                                    width: '10px'
                                }
                            }}
                        >
                            <Stack spacing={4} direction={'row'}>
                                {testi?.map((i, key) => {
                                    return (
                                        <Box
                                            key={key}
                                            sx={{ borderRadius: 3, borderLeft: 4, borderTop: 4 }}
                                            minWidth={screenWidth > 499 ? '27vw' : '50vw'} borderRadius={3} bgcolor={'white'} p={3}>
                                            <Stack direction={'column'} spacing={2}>
                                                <Typography variant='h6' >
                                                    {i?.title}
                                                </Typography>
                                                <Typography fontWeight={500} >
                                                    {i?.description}
                                                </Typography>

                                                <Stack direction={'column'}>
                                                    <Typography fontWeight={600} variant='body2'>
                                                        {i?.reviewer_name}
                                                    </Typography>
                                                    <Typography variant='body2'>
                                                        {i?.reviewer_position}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    )
                                })}
                            </Stack>
                        </ImageList>
                    </Stack>

                </Container>
            </Box>
        </div >
    )
}

export default Testimonial