import { Box, CircularProgress, Divider, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ButtonComp from '../../../../context/ButtonComp'
import { Abc, AttachmentOutlined, EmojiEmotions, EmojiEmotionsOutlined, PhonelinkErase, Quickreply, SendOutlined } from '@mui/icons-material'
import axios from 'axios'
import SendBottomMedia from './SendBottomMedia'
import SendBottomEmoji from './SendBottomEmoji'
import SendBottomQuick from './SendBottomQuick'
import SendBottomTemplet from './SendBottomTemplet'

const SendBottom = ({ data, INBOX, value }) => {
    const [state, setState] = React.useState({
        text: "",
        loading: false,
        mediaDialog: false,
        type: "IMAGE",
        emojiDialog: false,
        quickDialog: false,
        sideBardDialog: false
    })

    async function sendText() {
        if (!state.text && state.loading) return
        setState({ ...state, loading: true })
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/inbox/send_text`, {
                text: state.text,
                toNumber: INBOX?.data?.openedChat?.sender_mobile,
                toName: INBOX?.data?.openedChat?.sender_name,
                chatId: INBOX?.data?.openedChat?.chat_id
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
                }
            }).then((res) => {
                if (!res.data?.success) {
                    alert(res.data?.msg)
                    setState({ ...state, loading: false })
                    return
                }
                setState({ ...state, loading: false, text: "" })
                console.log(res.data)
            }).catch((err) => {
                console.log(err)
                setState({ ...state, loading: false })
            })

        } catch (err) {
            alert("Please check internet conenction")
            console.log(err)
        }
    }

    return (
        <Box p={1.5} >
            {value !== "Expired" &&
                <TextField
                    multiline
                    value={state.text}
                    onChange={(e) => {
                        setState({
                            ...state,
                            text: e.target.value?.replace("{{var1}}", INBOX?.data?.openedChat?.contact?.var1 || "{{var1}}")
                                ?.replace("{{var2}}", INBOX?.data?.openedChat?.contact?.var2 || "{{var2}}")
                                ?.replace("{{var3}}", INBOX?.data?.openedChat?.contact?.var3 || "{{var3}}")
                                ?.replace("{{var4}}", INBOX?.data?.openedChat?.contact?.var4 || "{{var4}}")
                                ?.replace("{{var5}}", INBOX?.data?.openedChat?.contact?.var5 || "{{var5}}")
                        })
                    }}
                    maxRows={3}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            // Call your function here
                            sendText();
                            // Prevent the default behavior of the Enter key (new line)
                            e.preventDefault();
                        }
                    }}
                    variant='filled'
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                            borderRadius: 0
                        },
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    <Divider orientation="vertical" variant="middle" sx={{ height: 23 }} />

                                    {INBOX?.data?.openedChat?.contact &&
                                        <Tooltip title={data.youMayUserVariables} >
                                            <Abc sx={{ color: (t) => t.palette.secondary.light }} />
                                        </Tooltip>}

                                    <Tooltip title={data.sendQuickreply} >
                                        <IconButton
                                            onClick={() => {
                                                setState({ ...state, quickDialog: true })
                                            }}
                                        >
                                            <Quickreply sx={{ height: 20, width: 20 }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={data.sendAttachments} >
                                        <IconButton
                                            onClick={() => {
                                                setState({ ...state, mediaDialog: true })
                                            }}
                                        >
                                            <AttachmentOutlined sx={{ height: 20, width: 20 }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={data.sendEmoji} >
                                        <IconButton
                                            onClick={() => {
                                                setState({ ...state, emojiDialog: true })
                                            }} >
                                            <EmojiEmotionsOutlined sx={{ height: 20, width: 20 }} />
                                        </IconButton>
                                    </Tooltip>

                                    <ButtonComp
                                        disabled={state.loading}
                                        startIcon={state.loading ?
                                            <CircularProgress color='inherit' size={15} />
                                            : <SendOutlined sx={{ height: 20, width: 20 }} />}
                                        title={data.send}
                                    />

                                </Stack>
                            </InputAdornment>
                        ),
                        sx: {
                            fontSize: ".875rem;",
                            alignItems: "flex-start"
                        }
                    }}
                    fullWidth
                    label={data.typeMsg}
                />}

            {value === "Expired" &&
                <Box bgcolor={'#FFE9E9'} p={2}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <PhonelinkErase
                            sx={{ color: '#EE6565' }}
                        />
                        <Typography
                            fontStyle={'italic'}
                            color={'#EE6565'}
                        >{data.chatExpiredMsg} <a
                            onClick={() => setState({ ...state, dialogTemplet: true })}
                            style={{ color: 'green', fontWeight: 600, cursor: 'pointer' }} >{data?.orYourCanSendTemplets}</a></Typography>
                    </Stack>
                </Box>}

            <SendBottomTemplet
                data={data}
                INBOX={INBOX}
                state={state}
                setState={setState}
            />

            <SendBottomQuick
                data={data}
                INBOX={INBOX}
                state={state}
                setState={setState}
            />

            <SendBottomEmoji
                data={data}
                INBOX={INBOX}
                state={state}
                setState={setState}
            />

            <SendBottomMedia
                data={data}
                INBOX={INBOX}
                state={state}
                setState={setState}
            />
        </Box>
    )
}

export default SendBottom