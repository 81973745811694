import React from 'react'
import Header from './components/Header'
import { TranslateContext } from '../context/TranslateContext'
import Hero from './components/Hero'
import HeroOne from './components/HeroOne'
import Features from './components/Features'
import Testimonial from './components/Testimonial'
import Plans from './components/Plans'
import Faq from './components/Faq'
import Page from './components/Page'
import Footer from './components/Footer'
import { LinearProgress } from '@mui/material'
import { GlobalContext } from '../context/GlobalContext'

const FrontEnd = () => {
    const { data } = React.useContext(TranslateContext)
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const { hitAxios } = React.useContext(GlobalContext)
    const [web, setWeb] = React.useState({})

    async function getWebPublic() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    React.useEffect(() => {
        getWebPublic()
    }, [])

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    const redirePage = (url) => {
        window.location.href = url;
    }

    return (
        web ?
            parseInt(web.is_custom_home) > 0 ?
                redirePage(web.custom_home)
                :
                <div>
                    <Header
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <Hero
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <HeroOne
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <Features
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <Testimonial
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <Plans
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <Faq
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <Page
                        screenWidth={screenWidth}
                        data={data}
                    />

                    <Footer
                        screenWidth={screenWidth}
                        data={data}
                    />
                </div> :
            <LinearProgress />
    )
}

export default FrontEnd