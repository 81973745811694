import { Box } from '@mui/material'
import React from 'react'
import OneChat from './messages/OneChat'

const ChattingHere = ({ chatConvo, data, value }) => {
    return (
        <Box  >
            {chatConvo && chatConvo?.map((i, key) => {
                return (
                    <OneChat
                        value={value}
                        chatConvo={chatConvo} key={key} data={data} i={i} />
                )
            })}
        </Box>
    )
}

export default ChattingHere