import {
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import moment from "moment";
import {
  Check,
  Close,
  DoneAll,
  ListOutlined,
  MenuBook,
} from "@mui/icons-material";

const ListMsgOutgoing = ({ i, data, notShow, chatConvo }) => {
  const [menu, setMenu] = React.useState({
    dialog: false,
  });

  return (
    <div id={i?.metaChatId}>
      <Dialog
        fullWidth
        open={menu.dialog}
        onClose={() => setMenu({ ...menu, dialog: false })}
      >
        <Box sx={{ bgcolor: (t) => t.palette.primary.main }} p={1}>
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <IconButton onClick={() => setMenu({ ...menu, dialog: false })}>
              <Close sx={{ color: "white" }} />
            </IconButton>

            <Typography color={"white"}>
              {i?.msgContext?.interactive &&
                i?.msgContext?.interactive?.action?.button}
            </Typography>
          </Stack>
        </Box>
        <Box p={2}>
          <Stack spacing={2} direction={"column"}>
            {i?.msgContext?.interactive &&
              i?.msgContext?.interactive?.action?.sections?.length > 0 &&
              i?.msgContext?.interactive?.action?.sections[0]?.rows?.map(
                (i, key) => {
                  return (
                    <Stack direction={"row"} spacing={2}>
                      <Typography>{i?.title}</Typography>
                    </Stack>
                  );
                }
              )}
          </Stack>
        </Box>
      </Dialog>

      <Stack
        alignItems={"flex-end"}
        justifyContent={"flex-end"}
        direction={"row"}
      >
        {!notShow && (
          <Box ml={0.6} mb={1}>
            <Stack spacing={0.5} alignItems={"center"} direction={"row"}>
              <Typography fontSize={10} color={"gray"}>
                {moment.unix(i?.timestamp).format("DD/MM/YYYY hh:mm A")}
              </Typography>

              {!i?.status && <Check sx={{ height: 18, width: 18 }} />}
              {i?.status === "delivered" && (
                <DoneAll sx={{ eight: 18, width: 18 }} />
              )}
              {i?.status === "read" && (
                <DoneAll sx={{ eight: 18, width: 18, color: "#4CA340" }} />
              )}
            </Stack>
          </Box>
        )}
        <Box
          width={notShow ? "100%" : undefined}
          maxWidth={!notShow ? 505 : undefined}
          bgcolor={"#dcf1e5"}
          p={1.5}
          sx={{
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            m: 1,
          }}
        >
          <Box sx={{ wordWrap: "break-word" }} position={"relative"}>
            <Stack minWidth={200} spacing={1} direction={"column"}>
              <Typography variant="body2" fontWeight={600}>
                {i?.msgContext?.interactive &&
                  i?.msgContext?.interactive?.header?.text}
              </Typography>

              <Typography variant="body2" fontWeight={600}>
                {i?.msgContext?.interactive &&
                  i?.msgContext?.interactive?.body?.text}
              </Typography>

              <Typography variant="caption" color={"gray"}>
                {i?.msgContext?.interactive &&
                  i?.msgContext?.interactive?.footer?.text}
              </Typography>

              <Button
                onClick={() => {
                  setMenu({ ...menu, dialog: true });
                }}
                startIcon={<ListOutlined sx={{ height: 20, width: 20 }} />}
                sx={{ borderRadius: 2 }}
                size="small"
                variant="outlined"
              >
                {i?.msgContext?.interactive &&
                  i?.msgContext?.interactive?.action?.button}
              </Button>

              {i?.msgContext?.interactive &&
                i?.msgContext?.interactive?.action?.buttons?.map((i, key) => {
                  return (
                    <Chip
                      color="primary"
                      sx={{ minWidth: 200 }}
                      label={i?.reply?.title}
                    />
                  );
                })}
            </Stack>

            <Box right={0} position={"absolute"}>
              <Box sx={{ cursor: "pointer" }}>
                <Typography variant="h7">{i?.reaction}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </div>
  );
};

export default ListMsgOutgoing;
