import { Box, Chip, Stack } from '@mui/material';
import React from 'react'
import SideBar from './SideBar';
import SideScreen from './SideScreen';
import { FacebookOutlined, ScheduleSend } from '@mui/icons-material';

const BroadCast = ({ data }) => {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [state, setState] = React.useState({
        sideBar: false,
        addDialog: false
    })
    const [menu, setMenu] = React.useState({
        selected: "CREATE_TEMPLET"
    })

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    return (
        <div>
            <Stack direction={'row'}>
                <SideBar
                    setMenu={setMenu}
                    menu={menu}
                    state={state}
                    setState={setState}
                    data={data}
                    screenWidth={screenWidth}
                />

                <Box sx={{ width: screenWidth > 899 ? screenWidth - 250 : "100%" }}>

                    {screenWidth < 900 &&
                        <Box p={2} pr={2} pl={2} >
                            <Stack direction='row' spacing={2}>
                                <Chip
                                    icon={<FacebookOutlined sx={{ color: "gray", height: 20, width: 20 }} />}
                                    variant={menu.selected === "CREATE_TEMPLET" ? 'filled' : 'outlined'}
                                    onClick={() => setMenu({ ...menu, selected: "CREATE_TEMPLET" })}
                                    label={data.manageTemplet}
                                />
                                <Chip
                                    icon={<ScheduleSend sx={{ color: "gray", height: 20, width: 20 }} />}
                                    variant={menu.selected === "SEND_BROADCAST" ? 'filled' : 'outlined'}
                                    onClick={() => setMenu({ ...menu, selected: "SEND_BROADCAST" })}
                                    label={data.sendBroadcast}
                                />
                            </Stack>
                        </Box>
                    }


                    <SideScreen
                        setMenu={setMenu}
                        menu={menu}
                        state={state}
                        setState={setState}
                        data={data}
                        screenWidth={screenWidth}
                    />
                </Box>
            </Stack>
        </div>
    )
}

export default BroadCast