import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { AccountCircleOutlined, BroadcastOnHome, BroadcastOnHomeOutlined, DatasetLinked, DatasetLinkedOutlined, Facebook, Forum, ForumOutlined, Logout, MoreHoriz, PaidOutlined, PermContactCalendar, PermContactCalendarOutlined, PersonalVideo, Quickreply, QuickreplyOutlined, SmartToy, SmartToyOutlined, SpaceDashboard, SpaceDashboardOutlined } from '@mui/icons-material';
import { CardActionArea, CardMedia, CircularProgress, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProfileDialog from './ProfileDialog';
import { GlobalContext } from '../context/GlobalContext';


function DashboardHeader({ data, page, setPage }) {
    const { hitAxios } = React.useContext(GlobalContext)
    const [web, setWeb] = React.useState({})
    const [states, setStates] = React.useState({
        dialog: false,
        dialogType: ""
    })
    const [state, setState] = React.useState({
        drawer: false
    })
    const [profile, setProfile] = React.useState({
    })

    const history = useHistory()
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    async function getProfile() {
        const res = await hitAxios({
            path: "/api/user/get_me",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setProfile({ ...res.data.data })
        }
    }

    async function getWeb() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    React.useEffect(() => {
        getWeb()
        getProfile()
    }, [])

    const menuData = [{
        icon: <SpaceDashboard sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }} />,
        iconOutlined: <SpaceDashboardOutlined sx={{ color: 'gray', height: 20, width: 20 }} />,
        title: data.dashboard,
        slug: "dashboard"
    }, {
        icon: <Forum sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }} />,
        iconOutlined: <ForumOutlined sx={{ color: 'gray', height: 20, width: 20 }} />,
        title: data.inbox,
        slug: "inbox"
    }, {
        icon: <SmartToy sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }} />,
        iconOutlined: <SmartToyOutlined sx={{ color: 'gray', height: 20, width: 20 }} />,
        title: data.chatbot,
        slug: "chatbot"
    }, {
        icon: <Quickreply sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }} />,
        iconOutlined: <QuickreplyOutlined sx={{ color: 'gray', height: 20, width: 20 }} />,
        title: data.chatbotFlow,
        slug: "chatbot-flow"
    }, {
        icon: <PermContactCalendar sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }} />,
        iconOutlined: <PermContactCalendarOutlined sx={{ color: 'gray', height: 20, width: 20 }} />,
        title: data.phonebook,
        slug: "phonebook"
    }, {
        icon: <BroadcastOnHome sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }} />,
        iconOutlined: <BroadcastOnHomeOutlined sx={{ color: 'gray', height: 20, width: 20 }} />,
        title: data.broadcast,
        slug: "broadcast"
    }, {
        icon: <DatasetLinked sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }} />,
        iconOutlined: <DatasetLinkedOutlined sx={{ color: 'gray', height: 20, width: 20 }} />,
        title: data.apiAccess,
        slug: "api-access"
    }]

    return (
        <AppBar sx={{ boxShadow: 0, borderBottom: 1, borderBottomColor: '#EAEAEA' }} color='inherit' position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>


                    <Box maxWidth={80} sx={{ display: { xs: 'none', lg: 'flex' } }} >
                        <CardActionArea
                            onClick={() => history.push("/")}
                            sx={{ borderRadius: 2 }}>
                            <CardMedia
                                component={'img'}
                                sx={{ height: "100%", width: 60, borderRadius: 2 }}
                                src={`/media/${web?.logo}`}
                            />
                        </CardActionArea>
                    </Box>



                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => setState({ ...state, drawer: true })}
                            color="inherit"
                        >
                            <MoreHoriz />
                        </IconButton>


                        <Drawer
                            key="2"
                            sx={{ backdropFilter: "blur(5px)" }}
                            onClose={() => setState({ ...state, drawer: false })}
                            anchor='left'
                            open={state.drawer}>

                            <Box
                                sx={{ width: 250 }}
                                role="presentation"
                            >

                                <Stack spacing={2} alignItems={'center'} direction={'column'} p={2}>
                                    <PersonalVideo sx={{ height: 50, width: 50 }} />
                                    <Typography align='center' color={'gray'} >{data.requestDesktop}</Typography>
                                </Stack>
                                <List component="nav">
                                    {
                                        menuData.map((item, key) => {
                                            return (
                                                <ListItemButton
                                                    key={key}
                                                    onClick={() => {
                                                        history.push(`/user?page=${item.slug}`)
                                                        setPage(item.slug)
                                                    }}
                                                    sx={{ bgcolor: page === item.slug ? "action.selected" : null, borderRadius: 3, height: 43 }} key={key} >
                                                    <ListItemIcon >
                                                        {page === item.slug ? item.icon : item.iconOutlined}
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography
                                                        sx={{ color: (t) => page === item.slug ? t.palette.primary.main : "gray" }}
                                                        fontWeight={page === item.slug ? 500 : 400}
                                                    >{item.title}</Typography>} />
                                                </ListItemButton>
                                            )
                                        })
                                    }
                                </List>
                            </Box>

                        </Drawer>



                    </Box>


                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                        <Box maxWidth={80} >
                            <CardActionArea
                                onClick={() => history.push("/")}
                                sx={{ borderRadius: 2 }}>
                                <CardMedia
                                    component={'img'}
                                    sx={{ height: "100%", width: 80, borderRadius: 2 }}
                                    src={`/media/${web?.logo}`}
                                />
                            </CardActionArea>
                        </Box>
                    </Box>

                    <Box ml={2} sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                        <Stack direction={'row'} spacing={3}>
                            {menuData.map((i, key) => {
                                return (
                                    <Stack
                                        alignItems={'center'}
                                        onClick={() => {
                                            history.push(`/user?page=${i.slug}`)
                                            setPage(i.slug)
                                        }}
                                        sx={{ cursor: 'pointer' }} key={key} direction={'row'} spacing={1}>
                                        {page === i.slug ? i.icon : i.iconOutlined}
                                        <Typography
                                            sx={{ color: (t) => page === i.slug ? t.palette.primary.main : "gray" }}
                                            fontWeight={page === i.slug ? 500 : 400}  >{i.title}</Typography>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Box>


                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            {profile?.id ?
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar>
                                        {profile?.name?.slice(0, 1)?.toUpperCase()}
                                    </Avatar>
                                </IconButton> :
                                <CircularProgress />}
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={() => setAnchorElUser(false)}
                            open={Boolean(anchorElUser)}
                        >

                            <ProfileDialog
                                states={states}
                                setStates={setStates}
                                data={data}
                            />

                            <Box width={250}>
                                <CardActionArea
                                    onClick={() => {
                                        setStates({ ...states, dialog: true, dialogType: "API_META" })
                                    }}
                                    sx={{ p: 1 }} >
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <Facebook sx={{ color: (t) => t.palette.primary.main }} />
                                        <Typography
                                            fontWeight={500}
                                            variant='body2' >{data.metaApiKeys}</Typography>
                                    </Stack>
                                </CardActionArea>

                                <CardActionArea
                                    onClick={() => {
                                        setStates({ ...states, dialog: true, dialogType: "PROFILE" })
                                    }}
                                    sx={{ p: 1 }} >
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <AccountCircleOutlined sx={{ color: (t) => t.palette.primary.main }} />
                                        <Typography
                                            fontWeight={500}
                                            variant='body2' >{data.profile}</Typography>
                                    </Stack>
                                </CardActionArea>

                                <CardActionArea
                                    onClick={() => {
                                        setStates({ ...states, dialog: true, dialogType: "SUBSCRIPTION" })
                                    }}
                                    sx={{ p: 1 }} >
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <PaidOutlined sx={{ color: (t) => t.palette.primary.main }} />
                                        <Typography
                                            fontWeight={500}
                                            variant='body2' >{data.subscription}</Typography>
                                    </Stack>
                                </CardActionArea>

                                <CardActionArea
                                    onClick={() => {
                                        localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user")
                                        history.push("/user/login")
                                    }}
                                    sx={{ p: 1 }} >
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <Logout sx={{ color: (t) => t.palette.primary.main }} />
                                        <Typography
                                            fontWeight={500}
                                            variant='body2' >{data.logout}</Typography>
                                    </Stack>
                                </CardActionArea>
                            </Box>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default DashboardHeader;
