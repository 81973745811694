import { AppBar, Box, Button, CardActionArea, CardMedia, Chip, Container, Drawer, IconButton, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { Link, useHistory } from 'react-router-dom'
import { AutoStories, ContactMailOutlined, Gavel, Login, Security } from '@mui/icons-material'
import ButtonComp from '../../context/ButtonComp'
import { Menu } from '@mui/icons-material'

const Header = ({ data, screenWidth }) => {
    const [web, setWeb] = React.useState({})
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        drawer: false
    })

    const history = useHistory()

    async function getWeb() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    React.useEffect(() => {
        getWeb()
    }, [])

    return (
        web?.id ?
            <div>
                <Box p={0} >
                    <Container maxWidth='lg'>
                        {screenWidth < 869 ?

                            <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>

                                <Stack direction={'row'} spacing={2}>
                                    <Chip
                                        onClick={() => setState({ ...state, drawer: true })}
                                        icon={<Menu sx={{ height: 18, width: 18 }} />}
                                        label={data.menu}
                                    />
                                </Stack>

                                <Drawer
                                    onClose={() => setState({ ...state, drawer: false })}
                                    anchor='top' open={state.drawer}>

                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton component={Link} to={`/view/privacy-policy`} onClick={() => setState({ ...state, drawer: false })} >
                                                <ListItemIcon>
                                                    <Security />
                                                </ListItemIcon>
                                                <ListItemText primary={data.privacyPlicy} />
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem disablePadding>
                                            <ListItemButton component={Link} to={`/view/terms-and-conditions`} onClick={() => setState({ ...state, drawer: false })} >
                                                <ListItemIcon>
                                                    <Gavel />
                                                </ListItemIcon>
                                                <ListItemText primary={data.termsCondition} />
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem disablePadding>
                                            <ListItemButton component={Link} to={"/page/contact-form"} onClick={() => setState({ ...state, drawer: false })} >
                                                <ListItemIcon>
                                                    <ContactMailOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary={data.contactUs} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>

                                </Drawer>

                                <Box maxWidth={80}>
                                    <CardActionArea
                                        onClick={() => history.push("/")}
                                        sx={{ borderRadius: 2 }}>
                                        <CardMedia
                                            component={'img'}
                                            sx={{ height: "100%", width: 80, borderRadius: 2 }}
                                            src={`/media/${web?.logo}`}
                                        />
                                    </CardActionArea>
                                </Box>

                                <Stack direction={'row'} spacing={2}>
                                    <ButtonComp
                                        onClick={() => history.push('/user')}
                                        padding={4}
                                        fontSize={12}
                                        startIcon={<Login sx={{ height: 18, width: 18 }} />}
                                        title={data.dashboard}
                                    />
                                </Stack>
                            </Stack>
                            :

                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Stack alignItems={'center'} direction={'row'} spacing={10}>
                                    <Box maxWidth={80}>
                                        <CardActionArea
                                            onClick={() => history.push("/")}
                                            sx={{ borderRadius: 2 }}>
                                            <CardMedia
                                                component={'img'}
                                                sx={{ height: "100%", width: 80, borderRadius: 2 }}
                                                src={`/media/${web?.logo}`}
                                            />
                                        </CardActionArea>
                                    </Box>

                                    <Stack alignItems={'center'} direction={'row'} spacing={3}>
                                        <Typography
                                            onClick={() => history.push("/view/privacy-policy")}
                                            sx={{ cursor: "pointer" }}
                                            fontWeight={600}
                                        >{data.privacyPlicy}</Typography>

                                        <Typography
                                            onClick={() => history.push("/view/terms-and-conditions")}
                                            sx={{ cursor: "pointer" }}
                                            fontWeight={600}
                                        >{data.termsCondition}</Typography>

                                        <Typography
                                            onClick={() => history.push("/page/contact-form")}
                                            sx={{ cursor: "pointer" }}
                                            fontWeight={600}
                                        >{data.contactUs}</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction={'row'} spacing={2}>
                                    <ButtonComp
                                        onClick={() => history.push('/user')}
                                        startIcon={<Login />}
                                        title={data.dashboard}
                                    />
                                </Stack>
                            </Stack>}
                    </Container>
                </Box>
            </div > :
            <LinearProgress />
    )
}

export default Header